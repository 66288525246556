import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommunitiesService } from '../communities/services/communities.service';
import { ToastService } from 'src/app/sharedServices/toast.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { GoogleAnalyticsService } from '../sharedServices/google-analytics.service';

@Component({
  selector: 'app-federated-user-details',
  templateUrl: './federated-user-details.component.html',
  styleUrls: ['./federated-user-details.component.css']
})
export class FederatedUserDetailsComponent implements OnInit {

  federatedUserDetailsForm: FormGroup;
  submitted = false;
  acceptTermsCheck: string;
  userTypeList: any = ['Private User', 'Business User'];
  accountPrivateList: any = ['Free Version', 'Premium Version'];
  accountBusinessList: any = ['Premium Version'];
  paymentTypeList: any = ['Payment Monthly'];
  userName: string;
  firstTimeLogin = false;
  tncOverlay = false;
  isUserByReferral = false;
  referralUserId: any = '';
  referralUserDetails: any;
  isUserTypeBusiness = false;
  inclusiveCharacters = 'a-zA-Z äöüÄÖÜßÀÂÉÊÈËÌÏÎÔÙÛÇÆŒàáâéêèëìïîôùûçæœ';
  constructor(
    private formBuilder: FormBuilder,
    private route: Router,
    public translate: TranslateService,
    private communityService: CommunitiesService,
    private toastService: ToastService,
    private storageService: StorageService,
    private analyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.federatedUserDetailsForm = this.formBuilder.group({
      referralUserName: [''],
      userType: ['', Validators.required],
      termsConditions: [false, Validators.requiredTrue],
      companyName: [''],
      vatNumber: ['']
    });
    this.referralUserDetails = this.storageService.getReferralUserDetails();
    if (this.referralUserDetails) {
      this.isUserByReferral = true; 
      this.referralUserId = this.referralUserDetails.user_id;
      this.federatedUserDetailsForm.patchValue({
        referralUserName: this.referralUserDetails.firstname + ' ' + this.referralUserDetails.lastname  
      });
    }
    
    if (window.location.search.includes('firstTimeLogin')) {
      this.firstTimeLogin = true;
    }
  }

  get federatedUserDetails() {
    return this.federatedUserDetailsForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.federatedUserDetailsForm.reset();

  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (!this.federatedUserDetailsForm.invalid) {
      if (this.federatedUserDetailsForm.value.userType && this.federatedUserDetailsForm.value.termsConditions == true) {
        this.updateFederatedUserAttributes(this.federatedUserDetailsForm.value.userType, this.federatedUserDetailsForm.value.termsConditions);
      } else {
        return;
      }
    } 
  }

  async updateFederatedUserAttributes(userType, termsConditions) {
    this.acceptTermsCheck = termsConditions;
    const user = await Auth.currentAuthenticatedUser();
    const paymentInfo = {
      paymentStatus: 'unPaid',
      paymentDate: 'NA',
      subscriptionId: 'NA'
    }

    if (!(user.attributes.hasOwnProperty('custom:userType'))) {
      const eventParams = {
        "method": "google",
        "first_field_id": user.username,
      };
      this.analyticsService.sendEvent('sign_up', eventParams);
      await Auth.updateUserAttributes(user, {
        'custom:userType': userType,
        'custom:accountType': 'NA',
        'custom:paymentType': 'NA',
        'custom:termsConditions': this.acceptTermsCheck.toString(),
        'custom:paymentInfo': JSON.stringify(paymentInfo),
        'address': 'Not updated',
        'picture': 'No photo uploaded.',
        'custom:languagePreference': 'DE',
        'custom:notifications': 'ON',
        'custom:identityId': 'Not available',
        'custom:referralUserId': this.referralUserId != '' ? this.referralUserId : 'NA',
        'custom:companyName': userType === 'Business User' ? this.federatedUserDetailsForm.get('companyName').value : 'NA',
        'custom:vatNumber': userType === 'Business User' ? this.federatedUserDetailsForm.get('vatNumber').value : 'NA',
        'custom:tcAcceptanceTime': new Date().getTime().toString()
      }).then(data => {
        this.onReset();
        // const address = JSON.parse(user.attributes.address);
        // this.httpService.getPostalCodesForNewUser(address.postalCode, address.country);
        this.communityService.joinOfficialCommunity().subscribe(() => {
          localStorage.removeItem('firstLogin');
          this.route.navigate(["/dashboard"]);
          this.toastService.showSuccess(this.translate.instant('login.details_updated'));
        });
      });
    } else {
      // alert(this.translate.instant("AccountExists"));
      this.toastService.showError("Account Already Exists");
      this.route.navigate(["/dashboard"]);
    }
  }

  onUserTypeSelect(){
    const selectedUserType = this.federatedUserDetailsForm.get('userType').value;
    if (selectedUserType === 'Business User') {
      this.federatedUserDetailsForm.get('companyName').setValidators([Validators.required, Validators.maxLength(50)]);
      this.isUserTypeBusiness = true;
    } else {
      this.federatedUserDetailsForm.get('companyName').clearValidators();
      this.federatedUserDetailsForm.get('companyName').setErrors(null);
      this.isUserTypeBusiness = false;
    }
  }
}
