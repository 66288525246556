<section class="terms-section">
    <div class="container terms-wrapper">
        <br>
        <div class="row">
            <div class="col-12" *ngIf="currentLanguage == 'DE'">
                <h4>Allgemeine Nutzungsbedingungen der Plattform www.Tresorvino.de</h4><br>

                <h5>§ 1 Allgemeines, Anwendungsbereich</h5><br>
                <p>(1a) Diese Allgemeinen Nutzungsbedingungen gelten für alle rechtlichen Beziehungen der Fa. Baroxx
                    Projektmanagement GmbH (nachstehend: „Plattformbetreiber“) gegenüber den Nutzern der Internetseite
                    www.tresorvino.de und allen Unterseiten (nachfolgend auch: „Internetseite“). Als Nutzer gilt jeder,
                    der sich auf der Internetseite registriert.
                </p>
                <p>(1b)<strong> Während der Beta-Testphase ist §15 zu berücksichtigen</strong>, der einige nachfolgenden
                    Paragraphen ersetzt bzw. aussetzt.
                </p>
                <p>(2) Abweichende Vorschriften der Nutzer gelten nicht, außer der Plattformbetreiber hat dies
                    ausdrücklich und schriftlich bestätigt. Individualvereinbarungen haben stets Vorrang.
                </p>
                <p>(3) Der Vertragstext wird vom Plattformbetreiber nach dem Vertragsschluss nicht gespeichert und ist
                    deshalb nicht zugänglich. Die Vertragssprache ist deutsch. Der Nutzer kann diese Allgemeinen
                    Nutzungsbedingungen abrufen, speichern und ausdrucken.
                </p>
                <p>(4) Die Geschäftsbeziehungen zwischen dem Plattformbetreiber und dem Nutzer unterliegen dem Recht der
                    Bundesrepublik Deutschland. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der
                    gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen
                    gewöhnlichen Aufenthalt hat, entzogen wird. Die Geltung von UN‑Kaufrecht ist ausgeschlossen.
                </p>
                <p>(5) Gerichtsstand ist Heidelberg, soweit der Nutzer Kaufmann, eine juristische Person des
                    öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen ist. Dasselbe gilt, wenn ein
                    Kunde keinen allgemeinen Gerichtsstand in Deutschland hat oder der Wohnsitz oder gewöhnlichen
                    Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt sind.
                </p>
                <p>(6) Informationspflicht gem. Verbraucherstreitbeilegungsgesetz (§36 VSBG): Der Plattformbetreiber ist
                    zur Teilnahme an weiteren Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder
                    bereit noch verpflichtet.
                </p>
                <p>(7) Der Plattformbetreiber ist weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren mit
                    Verbrauchern vor einer Verbraucherschlichtungsstelle teilzunehmen. Verbraucher haben jedoch die
                    Möglichkeit eine alternative Streitbeilegung zu nutzen. Der folgende Link der EU-Kommission (auch
                    OS-Plattform genannt) enthält Informationen über die Online-Streitschlichtung und dient als zentrale
                    Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten, die aus Online-Kaufverträgen oder
                    Online-Dienstleistungsverträgen erwachsen: <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>.
                </p>
                <p>(8) Der Plattformbetreiber ist bereit eine außergerichtlichen Streitbeilegung mit gewerblichen
                    Nutzern über folgende Mediatoren zu erzielen:
                </p>
                <p class="alignedParagraph">aktuell noch in Verhandlung
                </p>
                <p>Vor der Einleitung eines Mediationsverfahrens sollte eine Streitbeilegung unmittelbar mit dem
                    Plattformbetreiber versucht werden.
                </p>

                <br>

                <h5>§ 2 Leistungen des Plattformbetreibers, Inhalte der Internetseite</h5><br>
                <p>(1) Tresorvino ist eine Plattform auf der Nutzer ihren eigenen virtuellen Weinkeller auf Basis ihres
                    realen Bestands anlegen können. Mit Hinblick auf etwaiger Wertsteigerungen bestimmter individueller
                    Weine ermittelt der Plattformbetreiber auf wöchentlicher Basis anhand einer globalen Marktanalyse
                    den Wert der einzelnen Weine des Weinbestands. Auf der Plattform können Weinliebhaber und
                    interessierte Personen ihren Weinbestand darstellen, sich vernetzen, sich gegenseitig informieren
                    und nach Belieben den eigenen Weinkeller zur Ansicht an andere Nutzer freigeben. Ferner können die
                    Nutzer über Communities eigene Beiträge anlegen oder an Communities von anderen Nutzern teilnehmen.
                    Zudem haben Nutzer die Möglichkeit untereinander einzelne Weine oder gar den ganzen Weinbestand zu
                    tauschen (dazu § 7 der Nutzungsbedingungen).
                </p>
                <p>(2) Der Plattformbetreiber verkauft virtuelle Vino-Coins. Der Nutzer kann die Vino-Coins mittels
                    verschiedener Bezahldienste kaufen. Kommt ein Nutzer mit den Zahlungsverpflichtungen in Verzug, so
                    kann der Plattformbetreiber nach den gesetzlichen Bestimmungen Schadensersatz verlangen und/ oder
                    vom Vertrag zurücktreten.
                </p>
                <p>Vino-Coins können für die Nutzung spezieller Funktionen genutzt werden. Hierzu gehört neben der
                    werbefreien Darstellung, der Nutzung eines erweiterten Reportings auch die Teilnahme am Marktplatz
                    und dem Austausch von Daten zwischen Tauschpartnern.
                </p>
                <p>Darüber stellen Guthaben in Vino-Coins keinen rechtlich anerkannten Vermögenswert außerhalb des
                    Angebots der Verkaufsplattform dar. Guthaben, die vom Nutzer eingezahlt wurden, sind grundsätzlich
                    nicht rückzahlbar (d. h. nicht auszahlbar oder sonst erstattbar). Der Ausschluss der Rückzahlung
                    lässt jedoch die gesetzlichen Widerrufs-, Rücktritts-, Kündigungs-, Gewährleistungs- und
                    Mängelrechte sowie sonstige zwingende Rechte des Nutzers unberührt und schränkt seine
                    diesbezüglichen Zahlungs- und sonstigen Ansprüche nicht ein. Ebenso wird der Rückzahlungsanspruch
                    nicht eingeschränkt, sofern das hinterlegte Guthaben nicht verjährt ist. Die Verjährungsfrist
                    beginnt drei Jahre nach Ablauf des Kalenderjahres, in dem die letzte Zahlung auf das Guthaben
                    geleistet wurde.
                </p>
                <p>Die Vino-Coins verstehen sich, wenn nicht ausdrücklich anders angegeben, inkl. der jeweils geltenden
                    Umsatzsteuer (derzeit 19 %). Der Nutzer erhält vom Plattformbetreiber über gekauften Vino-Coins eine
                    Rechnung im Einstellungsbereich des Nutzerkontos.
                </p>
                <p>(3) Durch die ständige Marktwertanalyse haben die Nutzer damit die Möglichkeit den Marktwert sowohl
                    des eigenen als auch den individuell freigeschalteten Weinbestands anderer Nutzer stets im Blick zu
                    haben und auf dieser Basis den eigenen Weinkeller als Investment zu nutzen.
                </p>
                <p>(4) Bei den vom Plattformbetreiber angezeigten Marktpreisen der angelegten Weine handelt es sich
                    Mittelwerte der entsprechenden im Internet angebotenen Marktwerte sowie die von den Nutzern
                    pflegbaren Preisindikatoren (Bid/Ask) als auch den Richtpreisen auf dem Marktplatz. Jeder Preis hat
                    eine Indikation mittels Ampelfarben. Durch Führen des Mauscursors über die angezeigten Ampeln
                    erhalten Nutzer nähere Informationen zu den entsprechenden Farben (Link Mouse over traffic light
                    explanation „prices“).
                </p>
                <p>(5) Der Plattformbetreiber kann die Nutzer der Internetseite durch Links auf fremde Inhalte und
                    Anwendungen Dritter (nachfolgend „Drittinhalte“) hinweisen. Solche Drittinhalte sind durch einen
                    entsprechenden Hinweis deutlich gekennzeichnet. Wenn und soweit im Zusammenhang mit diesen
                    Drittinhalten der Abschluss eines Vertrages angeboten wird, kommt dieser ausschließlich mit dem
                    jeweiligen Plattformbetreiber zustande.
                </p>
                <p>(6) Der Plattformbetreiber kann den Zugang zu den eigenen Leistungen beschränken, sofern die
                    Sicherheit des Netzbetriebes, die Aufrechterhaltung der Netzintegrität, insbesondere die Vermeidung
                    schwerwiegender Störungen des Netzes, der Software oder gespeicherter Daten dies erfordern.
                </p>

                <br>

                <h5>§ 3 Nutzung als registriertes Mitglied</h5><br>
                <p>(1) Die aktive Nutzung der Internetseite ist nur nach vorheriger Registrierung als Mitglied möglich.
                    Ein Nutzer kann sich dabei kostenlos registrieren. Die Nutzerzugänge unterscheiden sich durch den
                    Umfang der Nutzbarkeit der Plattform, z.B. hinsichtlich des Informationsangebots, der
                    Suchmöglichkeiten, der Communitynutzung und einer Tauschoption (Link Community). Der
                    Plattformbetreiber behält sich das Recht vor, die Nutzungsmöglichkeit der Private oder Business
                    Mitglieder mit einer Ankündigungsfrist von 4 Wochen zu ändern sowie ganz oder teilweise
                    einzustellen.
                </p>
                <p>(2) Die Registrierung erfolgt durch die Eröffnung eines Nutzerzugangs als Private oder Business
                    Mitglied unter Zustimmung dieser Allgemeinen Nutzungsbedingungen und der Kenntnisnahme der
                    Datenschutzerklärung (Link Datenschutzerklärung). Mit dem Abschluss des Registrierungsvorgangs kommt
                    zwischen dem Plattformbetreiber und dem jeweiligen Nutzer ein Vertrag über die Nutzung der
                    Internetseite (nachfolgend: „Nutzungsvertrag“) zustande. Bis zum Abschluss des
                    Registrierungszuganges kann der Nutzer seine Eingaben vor mit den üblichen Tastatur- und
                    Mausfunktionen direkt in den entsprechenden Eingabefeldern korrigieren. Der Plattformbetreiber
                    bestätigt dem Nutzer den Abschluss des Nutzungsvertrages per E‑Mail. Mit dieser E‑Mail erhält der
                    Nutzer auch diese Allgemeinen Nutzungsbedingungen, eine Widerrufsbelehrung sowie eine
                    Datenschutzerklärung. Ein Anspruch auf Abschluss eines Nutzungsvertrages besteht nicht.
                </p>
                <p>(3) Gewerbliche Nutzer sind verpflichtet bei der Registrierung die gewerbliche Nutzung des
                    Nutzerkontos anzuzeigen. Der Plattformbetreiber kennzeichnet dann das jeweilige Nutzerkonto als
                    "Gewerblicher Nutzer". Mit der Nutzung der Plattform für den gewerblichen Vertrieb von Waren sind
                    gewerbliche Nutzer verpflichtet die gesetzlichen Informationspflichten gegenüber Verbrauchern
                    einzuhalten. Insbesondere ist u.a. auf die Verpflichtung zur Erstellung eines eigenen Impressums und
                    Informationspflichten zu den gesetzlich vorgeschriebenen Vorschriften zum Verbraucherschutz
                    hinzuweisen. Der Verweis auf Bestimmungen und die Nutzung dieser Nutzungsbedingungen ist untersagt.
                </p>
                <p>(4) Die Registrierung ist nur unbeschränkt geschäftsfähigen natürlichen Personen erlaubt.
                    Minderjährige dürfen sich nicht beim Plattformbetreiber anmelden. Jeder Nutzer darf nur einen Zugang
                    haben; eine Übertragung des Zugangs ist nicht möglich.
                </p>
                <p>(5) Die vom Plattformbetreiber bei der Registrierung abgefragten Daten sind vollständig und korrekt
                    anzugeben, z.B. Vor- und Nachname, aktuelle Adresse (kein Postfach) und Telefonnummer, gültige
                    E-Mail-Adresse. Ändern sich die angegebenen Daten nachträglich, so ist der Nutzer verpflichtet, die
                    Angaben umgehend zu korrigieren. Gewerbliche Nutzer sind zudem verpflichtet ihren Firmennamen,
                    Firmensitz und die entsprechende Adresse anzugeben.
                </p>
                <p>(6) Bei der Anmeldung geben die Nutzer einen Nutzernamen an und wählen ein Passwort. Über die
                    angegebene E-Mail-Adresse erfolgt die Kommunikation zwischen dem Plattformbetreiber und den Nutzern.
                    Nutzer müssen ihr Passwort geheim halten.
                </p>
                <p>(7) Jeder Nutzer ist verpflichtet, den Plattformbetreiber umgehend zu informieren, wenn es
                    Anhaltspunkte dafür gibt, dass sein Zugang missbraucht wurde. Jeder Nutzer haftet grundsätzlich für
                    sämtliche Aktivitäten, die unter Verwendung seines Zugangs vorgenommen werden, und stellt den
                    Plattformbetreiber von etwaigen Schadensersatzansprüchen von Dritten frei, außer der Nutzer hat den
                    Missbrauch nicht zu vertreten.
                </p>

                <br>

                <h5>§ 4 Dauer des kostenpflichtigen Zugangs, Einstellung der Internetseite durch den Plattformbetreiber
                </h5><br>
                <p>(1) Die Laufzeit der Nutzung zusätzlich gekaufter Inhalte oder Funktionen via Vino-Coins beträgt ein
                    Monat. Nach diesem Zeitraum muss der Nutzer die Inhalte erneut durch Ausgabe von Vino-Coins
                    freischalten.
                </p>
                <p>(2) Die Registrierung als Private und Business Mitglied ist unbefristet. Ein Private und Business
                    Mitglied kann seinen Nutzungsvertrag jederzeit im Portal unter den Einstellungen, schriftlich oder
                    per E-Mail (kündigung@tresorvino.de) kündigen. Die Nutzungsdaten werden innerhalb einer Woche nach
                    Zugang der Kündigung gelöscht.
                </p>
                <p>(3) Der Plattformbetreiber behält sich vor, die Internetseite insgesamt einzustellen. Hierüber wird
                    er die Nutzer mindestens 6 Wochen vorher per E-Mail informieren. Etwaig zu diesem Zeitpunkt
                    bestehende Guthaben (Vino-Coins) verfallen unverzüglich mit der Einstellung des Dienstes.
                </p>
                <p>(4) Das Recht zur außerordentlichen Kündigung bleibt unberührt.
                </p>

                <br>

                <h5>§ 5 Besondere Bestimmungen zum Vertragsschluss für Mitglieder</h5><br>
                <p>(1) Im Rahmen Tauschmöglichkeiten der Nutzer mit einer Private oder Business Mitgliedschaft versteht
                    sich der Plattformbetreiber Tauschplattform im Internet. Bei den angelegten Weinen handelt es sich
                    um Produkte von Dritten, den Nutzern der Plattform. Vom Plattformbetreiber eingestellte Angebote
                    sind entsprechend markiert.
                </p>
                <p>(2) Ein Tauchvertrag kommt stets zwischen den jeweiligen Nutzern zustande; der Plattformbetreiber
                    stellt hierfür nur die technische Plattform zur Verfügung und wird nicht Vertragspartner der
                    Tauschverträge. Nur im Falle eigener Angebote ist der Plattformbetreiber Tauschpartner.
                </p>
                <p>(3) Zwischen den Nutzern kommt ein Tauschvertrag nach den gesetzlichen Bestimmungen durch Angebot und
                    Annahme zustande. Das Anlegen und die Veröffentlichung des eigenen Weinbestandes stellt kein Angebot
                    auf den Abschluss eines Tauschvertrages dar. Das Zustandekommen einer vertraglichen Beziehung
                    erfordert insofern eine im Rahmen eines kommunikativen Austauschs zustande gekommene individuelle
                    Vereinbarung der jeweiligen Nutzer. Für die Tauschverträge gelten – soweit vorhanden – die
                    Allgemeinen Geschäftsbedingungen der jeweiligen Nutzer, diese sind auch für die Erfüllung aller
                    ihnen obliegenden Belehrungspflichten verantwortlich. Individualabreden haben stets Vorrang. Im
                    Übrigen gelten die gesetzlichen Bestimmungen über Tauschverträge.
                </p>
                <p>(4) Der Plattformbetreiber behält sich diesbezüglich vor, Bestandsveränderungen der Nutzer zu
                    analysieren und Rückschlüsse auf etwaiges Tauschverhalten zu ziehen. Stellt der Plattformbetreiber
                    ein Verstoß gegen diese Bestimmung fest, dann gilt § 6 und § 7 dieser Nutzungsbedingungen
                    entsprechend.
                </p>
                <p>(5) Die tatsächliche Abwicklung des Tauschvertrags liegt in der Verantwortung der korrespondierenden
                    Nutzer. Wesentliche Vertragsbestandteile (wie z.B. Namen, Anschriften, Anzahl und Bestimmung der
                    Weine, Versand) werden vom Plattformbetreiber gegen Zahlung eines virtuellen Vino-Coins dem
                    jeweiligen Tauschpartner zur Verfügung gestellt.
                </p>
                <p>Ein auf dem Marktplatz verhandelter Preis ist lediglich eine Indikation für das folgende anderweitig
                    abzuschließende Rechtsgeschäft außerhalb der Plattform des Plattformbetreibers. Insofern der Tausch
                    im Marktplatz mind. einen Geschäftskunden als Partner enthält, muss die Nutzung der Preisindikation
                    auch inklusive der gesetzlich verpflichtenden Umsatzsteuer verstanden werden. Der Plattformbetreiber
                    stellt eine hervorgehobene Darstellung von Business Nutzern und deren Preisindikationen zur
                    Verfügung.
                </p>
                <p>Die vom Plattformbetreiber ermittelten aktuellen Marktwerte (Richtwerte) der Produkte i.S.d § 2 Abs.
                    2 der Nutzungsbedingung keine Geschäftsgrundlage. Der Plattformbetreiber übernimmt für die
                    Richtigkeit dieser Richtwerte keine Haftung (siehe § 10 Abs. 2).
                </p>
                <p>(6) Die Nutzer dürfen die im Rahmen der Vertragsverhandlungen erhaltenen Informationen anderer Nutzer
                    nur für den Zweck der vertraglichen oder vorvertraglichen Kommunikation nutzen. Eine weitere
                    Verarbeitung der Informationen jeglicher Art ist verboten.
                </p>
                <p>(7) Nach der vollständigen Abwicklung des Vertrages überträgt der Plattformbetreiber den entsprechend
                    virtuellen Bestand auf das Nutzerkonto des entsprechenden Nutzers. Der Plattformbetreiber behält
                    sich vor, im Falle schwerwiegender vertraglicher Pflichtverletzungen, den virtuellen Bestand auf das
                    ursprüngliche Nutzerkonto zu übertragen. Schwerwiegende Pflichtverletzungen liegen beispielsweise
                    vor, wenn einem Nutzer die vertraglich festgelegten Produkte nicht zugestellt werden und der andere
                    Vertragspartner den tatsächlichen Versand nicht nachweisen kann.
                </p>
                <p>(8) Der Plattformbetreiber kann den Zugang zu den eigenen Leistungen beschränken, sofern die
                    Sicherheit des Netzbetriebes und/ oder die Aufrechterhaltung der Netzintegrität, insbesondere die
                    Vermeidung schwerwiegender Störungen des Netzes, der Software oder gespeicherter Daten, dies
                    erfordern.
                </p>

                <br>

                <h5>§ 6 Verhaltenspflichten der Nutzer, Freistellung bei Verstößen</h5><br>
                <p>(1) Die Beiträge der Nutzer auf der Internetseite werden vom Plattformbetreiber grundsätzlich nicht
                    auf Richtigkeit und Rechtmäßigkeit geprüft. Sollte der Plattformbetreiber Kenntnis davon erlangen,
                    dass ein Nutzer mit seinem Beitrag gegen diese Allgemeinen Nutzungsbedingungen oder gegen
                    gesetzliche Vorschriften verstößt, werden die rechtswidrigen Inhalte jedoch unverzüglich entfernt
                    oder der Zugang zu diesen wird gesperrt.
                </p>
                <p>(2) Allein der Nutzer ist dafür verantwortlich, dass er über alle Rechte hinsichtlich der von ihm
                    veröffentlichten Inhalte verfügt und dass dadurch keine Rechte Dritter verletzt werden. Sollen z.B.
                    markenrechtlich geschützte Bilder hochgeladen werden, so ist dies nur mit Zustimmung des
                    Lizenzinhabers möglich. Der Nutzer räumt dem Plattformbetreiber mit der Veröffentlichung
                    unwiderruflich und unentgeltlich das räumlich, zeitlich und inhaltlich unbeschränkte Recht zur
                    Nutzung und Verwertung der von ihm bereitgestellten Inhalte auf der Internetseite ein. Eine
                    anderweitige Nutzung der vom Nutzer übertragenen Inhalte ist nur mit dessen Zustimmung zulässig. In
                    keinem Fall stellen Inhalte die Meinung des Plattformbetreibers dar und der Plattformbetreiber macht
                    sich diese auch nicht zu Eigen.
                </p>
                <p>(3) Jeder Nutzer verpflichtet sich, das Angebot der Internetseite nicht zu nutzen, um Inhalte zu
                    veröffentlichen oder Nachrichten zu übermitteln, die bzw. deren Einstellung
                </p>
                <ul>
                    <li>sittenwidrig, pornographisch, rassistisch oder in sonst einer Weise anstößig sind,
                    </li>
                    <li>unsachlich oder vorsätzlich unwahr sind,
                    </li>
                    <li>ein Gütesiegel, Garantiezeichen oder sonstige Symbole Dritte enthalten,
                    </li>
                    <li>die Rechte Dritter, insbesondere Urheberrechte und geschützte Rechte nach dem MarkenG,
                        verletzen,
                    </li>
                    <li>in sonst einer Weise gegen geltende Gesetze verstoßen bzw. einen Straftatbestand erfüllen,
                    </li>
                    <li>Viren oder andere Computerprogramme beinhalten, welche Soft- oder Hardware beschädigen oder die
                        Nutzung von Computern beeinträchtigen können,
                    </li>
                    <li>Umfragen oder Kettenbriefe oder getarnte Werbung sind, oder
                    </li>
                    <li>dem Zweck dienen, persönliche Daten von anderen Nutzern zu insbesondere geschäftlichen Zwecken
                        zu sammeln und/ oder zu nutzen.
                    </li>
                </ul>

                <p>(4) Sollte der Plattformbetreiber von einem Verstoß gegen die vorstehenden Bestimmungen erfahren, so
                    bleibt es ihm vorbehalten, den Beitrag/ die Anzeige zu ändern oder zu löschen. Sollten Dritte wegen
                    eines solchen Verstoßes Schadensersatzansprüche gegenüber dem Plattformbetreiber geltend machen, so
                    hat der verantwortliche Nutzer den Plattformbetreiber hiervon freizustellen.
                </p>

                <br>

                <h5>§ 7 Sperrung</h5><br>
                <p>(1) Der Plattformbetreiber kann folgende Maßnahmen ergreifen, wenn konkrete Anhaltspunkte dafür
                    bestehen, dass ein Nutzer gesetzliche Vorschriften, Rechte Dritter oder diese Allgemeinen
                    Nutzungsbedingungen verletzt, oder wenn der Plattformbetreiber ein sonstiges berechtigtes Interesse
                    hat, insbesondere zum Schutz der anderen Nutzer:
                </p>
                <ul>
                    <li>Verwarnung von Nutzern,
                    </li>
                    <li>Vorläufige, teilweise oder endgültige Sperrung.
                    </li>
                </ul>
                <p>(2) Der Plattformbetreiber kann einen Nutzer endgültig von der aktiven Benutzung der Internetseite
                    auch dann ausschließen (endgültige Sperre), wenn er bei der Anmeldung falsche Kontaktdaten angegeben
                    hat, insbesondere eine falsche oder ungültige E-Mail-Adresse, wenn er andere Nutzer oder
                    Plattformbetreiber in erheblichem Maße schädigt oder wenn ein anderer wichtiger Grund vorliegt.
                </p>
                <p>(3) Sobald ein Nutzer vorläufig oder endgültig gesperrt wurde, darf er die Internetseite auch mit
                    anderen Nutzerzugängen nicht mehr nutzen und sich nicht erneut anmelden.
                </p>

                <br>

                <h5>§ 8 Systemintegrität und Störung der Internetseite</h5><br>
                <p>(1) Nutzer dürfen keine Mechanismen, Software oder sonstige Scripts in Verbindung mit der Nutzung der
                    Internetseite verwenden, die das Funktionieren der Internetseite stören können, insbesondere solche,
                    dies es ermöglichen, automatisierte Seitenaufrufe oder Seitenaufrufe zu generieren.
                </p>
                <p>(2) Nutzer dürfen keine Maßnahmen ergreifen, die eine unzumutbare oder übermäßige Belastung der
                    Infrastruktur zur Folge haben können.
                </p>
                <p>(3) Nutzer dürfen keine vom Plattformbetreiber generierten Inhalte blockieren, überschreiben oder
                    modifizieren oder in sonstiger Weise störend in die Internetseite eingreifen.
                </p>

                <br>

                <h5>§ 9 Verantwortung für Inhalte, Haftung</h5><br>
                <p>(1) Der Plattformbetreiber übernimmt keine Verantwortung dafür, dass die auf der Internetseite zur
                    Verfügung gestellten Informationen und Dokumentationen vollständig und richtig sind sowie dem
                    aktuellen Stand entsprechen. Dies gilt auch für alle auf der Internetseite enthaltenen Verlinkungen.
                    Der Plattformbetreiber ist für den Inhalt einer Seite, die mit einem solchen Link erreicht wird,
                    nicht verantwortlich.
                </p>
                <p>(2) Der Plattformbetreiber haftet insbesondere nicht für die von ihm ermittelten Markwerte der
                    Produkte i.S.d § 2 Abs. 2 der Nutzungsbedingungen. Der Nutzer hat keinen Anspruch auf Offenlegung
                    der bei der Ermittlung der Preise verwendeten Faktoren bzw. auf Offenlegung der Namen der
                    Plattformbetreiber, deren Preise die Berechnungsgrundlage für den auf der Plattform ermittelten
                    Marktwert dienen.
                </p>
                <p>(3) Außerhalb der Haftung für Sach- und Rechtsmängel haftet der Plattformbetreiber unbeschränkt,
                    soweit die Schadensursache auf Vorsatz oder grober Fahrlässigkeit beruht. Der Plattformbetreiber
                    haftet auch für die leicht fahrlässige Verletzung von wesentlichen Pflichten (Pflichten, deren
                    Verletzung die Erreichung des Vertragszwecks gefährdet) sowie für die Verletzung von
                    Kardinalpflichten (Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages
                    überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertraut), jedoch nur für
                    den vorhersehbaren, vertragstypischen Schaden. Insbesondere haftet der Plattformbetreiber für den
                    Verlust von Daten nur in Höhe des Aufwandes, der entsteht, wenn der Nutzer regelmäßig und
                    anwendungsadäquat eine Datensicherung durchgeführt und dadurch sichergestellt hat, dass verloren
                    gegangene Daten mit vertretbarem Aufwand wiederhergestellt werden können. Für die leicht fahrlässige
                    Verletzung anderer als der vorstehenden Pflichten haftet der Plattformbetreiber nicht.
                </p>
                <p>(4) Die Haftungsbeschränkungen des vorstehenden Absatzes gelten nicht bei der Verletzung von Leben,
                    Körper und Gesundheit, für einen Mangel nach Übernahme einer Garantie und bei arglistig
                    verschwiegenen Mängeln.
                </p>
                <p>(5) Ist die Haftung des Plattformbetreibers ausgeschlossen oder beschränkt, so gilt dies ebenfalls
                    für die persönliche Haftung der Angestellten, Vertreter und Erfüllungsgehilfen.
                </p>

                <br>

                <h5>§ 10 Datenschutz</h5><br>
                <p>(1) Dem Nutzer ist bekannt und er willigt darin ein, dass die zur Abwicklung des
                    Nutzungsverhältnisses erforderlichen persönlichen Daten auf Datenträgern gespeichert werden. Der
                    Nutzer stimmt der Erhebung, Verarbeitung und Nutzung seiner personenbezogenen Daten zum Zwecke der
                    Durchführung des Nutzungsvertrages ausdrücklich zu. Hierzu zählen alle Daten, die zur
                    ordnungsgemäßen Abwicklung des zwischen dem Nutzer und dem Plattformbetreiber zustande gekommenen
                    Vertrages erforderlich sind, also insbesondere Name, Adresse, Kontaktdaten (Telefon- und
                    Telefaxnummer, E-Mail-Adresse); aber auch die anonyme Auswertung von Dateien, die angefordert
                    wurden, sowie dem Namen der Datei, dem Datum und der Uhrzeit der Abfrage, der übertragene
                    Datenmenge, dem Zugriffsstatus (Datei übertragen, Datei nicht gefunden etc.), einer Beschreibung des
                    Typs des verwendeten Webbrowsers.
                </p>
                <p>(2) Im Rahmen der Community-Funktion sind personenbezogene Daten der Nutzer für andere Nutzer
                    grundsätzlich nicht sichtbar. Im Falle einer Verknüpfung mit anderen Nutzern (Hinzufügen zu einer
                    Freundesliste) werden personenbezogene Daten der verknüpften Nutzer (Name, Vorname, Firmenname) auf
                    den jeweiligen Accounts sichtbar, wenn sie dieser zustimmen. Die Zustimmung zur Sichtbarkeit der
                    personenbezogenen Daten erfolgt durch die Bestätigung der Verknüpfung zu anderen Nutzern
                    (Bestätigung der Hinzufügung zu einer Freundesliste).
                </p>
                <p>(3) Der Plattformbetreiber ist nur bei gesonderter Einwilligung des Nutzers berechtigt, dessen
                    personenbezogene Daten zum Zwecke der Werbung zu erheben, zu speichern, zu verändern oder zu nutzen.
                    Der Plattformbetreiber ist in keinem Fall berechtigt, diese Daten in listenmäßiger oder sonst wie
                    zusammengefasster Form zum Zwecke der Werbung an Dritte zu übermitteln.
                </p>
                <p>(4) Die gespeicherten persönlichen Daten werden vom Plattformbetreiber vertraulich behandelt. Die
                    Erhebung, Verarbeitung und Nutzung der personenbezogenen Daten erfolgt unter Beachtung des
                    Bundesdatenschutzgesetzes (BDSG) sowie des Telemediengesetzes (TMG). Dem Nutzer steht das Recht zu,
                    seine Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen. Der Plattformbetreiber ist
                    in diesem Fall zur Löschung der persönlichen Daten des Nutzers verpflichtet. Bei laufenden
                    Vertragsverhältnissen eines Nutzers erfolgt die Löschung nach Beendigung des Vertrages.
                </p>

                <br>

                <h5>§ 11 Jugendschutz und Altersverifikation</h5><br>
                <p>Der Nutzer bestätigt mit seiner Bestellung, dass er das jeweils zum Erwerb der altersbeschränkten
                    Produkte erforderliche Mindestalter erreicht hat.
                </p>
                <p>Zu den von der Altersbeschränkung umfassten Produkten gehören alkoholische Getränke.
                </p>

                <br>

                <h5>§ 12 Änderung dieser AGB</h5><br>
                <p>Der Plattformbetreiber behält sich vor, diese AGB jederzeit und ohne Nennung von Gründen zu ändern.
                    Die geänderten Bedingungen werden den Nutzern per E-Mail spätestens zwei Wochen vor ihrem
                    Inkrafttreten zugesendet. Widerspricht ein Nutzer der Geltung der neuen AGB nicht innerhalb von
                    sechs Wochen nach Empfang der E-Mail, gelten die geänderten AGB als angenommen. Der
                    Plattformbetreiber wird die Nutzer in der E-Mail, die die geänderten Bedingungen enthält, auf die
                    Bedeutung dieser Frist gesondert hinweisen.
                </p>

                <br>

                <h5>§ 13 Salvatorische Klausel</h5><br>
                <p>Sofern eine Bestimmung dieses Vertrags unwirksam ist, bleiben die übrigen Bestimmungen davon
                    unberührt. Die unwirksame Bestimmung gilt als durch eine solche ersetzt, die dem Sinn und Zweck der
                    unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für
                    eventuelle Regelungslücken.
                </p>

                <br>

                <h5>§ 14 Widerrufsrecht</h5><br>
                <p>Verbraucher haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
                    widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.
                </p>
                <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns Tresorvino Daten mittels einer eindeutigen Erklärung
                    (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag
                    zu widerrufen, informieren.
                </p>
                <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
                    Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.  
                </p>

                <br>

                <h5>Folgen des Widerrufs</h5><br>
                <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
                    einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass
                    Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung
                    gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem
                    die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
                    verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben,
                    es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
                    wegen dieser Rückzahlung Entgelte berechnet.
                </p>
                <p>Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie
                    uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von
                    der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten
                    Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen
                    entspricht.
                </p>


                <br>

                <h5>Muster-Widerrufsformular</h5><br>
                <p>Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück an:</p>

                <span>Tresorvino</span><br>
                <span>Baroxx Projektmanagement GmbH</span><br>
                <span>Am Büchsenackerhang 66</span><br>
                <span>69118 Heidelberg</span><br><br><br>

                <ul style="list-style-type:none;">
                    <li>- Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag die Erbringung der folgenden Dienstleistung (*) 
                        … <br>(ggf.zusätzliche Informationen)</li>
                    <hr class="formLine">
                    <hr class="formLine">
                    <hr class="formLine">
                    <li>- Bestellt am (*)/ erhalten am (*):</li>
                    <hr class="formLine">
                    <li>- Name des/ der Verbraucher(s):</li>
                    <hr class="formLine">
                    <li>- Anschrift des/ der Verbraucher(s):</li>
                    <hr class="formLine">
                    <br><br>
                    <hr class="formLine" style="margin-bottom: 0.5rem; margin-top: 3rem; width:60%; margin-left:0px;">

                    <p>Unterschrift des/ der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>

                    <hr class="formLine" style="margin-bottom: 0.5rem; margin-top: 3rem; width:45%; margin-left:0px;">

                    <p>Datum<br><br><br></p>
                    <h5>§ 15 Beta Version</h5>
                    <p>(1) Die Beta-Version befindet sich noch in einem abzuschließendem Testzustand. Sie müssen davon
                        ausgehen, dass noch nicht alle Produkteigenschaften vollständig funktionsfähig sind. Der Einsatz
                        dieser Beta-Version erfolgt deshalb in Ihrem ausschließlichen Risiko.</p>
                    <p>(2) Tresorvino behält sich das Recht vor, diese Nutzungsbedingungen jederzeit mit Wirkung für die
                        Zukunft zu ändern, soweit dies</p>
                    <p class="sub-point">a) zur Anpassung an Entwicklungen erforderlich ist, welche bei Beginn des
                        Nutzungsverhältnisses nicht vorhersehbar waren und welche Tresorvinox nicht veranlasst hat
                        oder beeinflussen kann und deren Nichtberücksichtigung die Ausgewogenheit des
                        Nutzungsverhältnisses in nicht unbedeutendem Maße stören würde und soweit hierdurch
                        wesentliche Regelungen der Nutzungsbedingungen nicht berührt werden oder;</p>
                    <p class="sub-point">b) zur Beseitigung von nicht unerheblichen Schwierigkeiten bei der Durchführung des
                        Vertrages aufgrund von nach Beginn des Nutzungsverhältnisses entstandenen
                        Regelungslücken erforderlich ist oder;</p>
                    <p class="sub-point">c) aus triftigem, bei Beginn des Nutzungsverhältnisses nicht vorhersehbarem Grund
                        erforderlich ist und diese Änderung das Verhältnis von Leistung und Gegenleistung nicht zu
                        Ungunsten des Partners verschiebt, so dass die Änderung für den Partner zumutbar ist.</p>
                    <p class="sub-point">d) Tresorvino wird die Änderung der Nutzungsbedingungen spätestens zwei Wochen vor
                        Wirksamwerden in Textform mitteilen. Widersprechen Sie den Änderungen der
                        Nutzungsbedingungen nicht bis zu deren Wirksamwerden, gelten die geänderten
                        Nutzungsbedingungen als angenommen. Widersprechen Sie den geänderten
                        Nutzungsbedingungen fristgemäß, so ist Tresorvino berechtigt die Nutzung der Plattform
                        einzuschränken oder untersagen.</p>
                    <p>(3) Die Beta-Version wird Ihnen deshalb ohne Vereinbarung einer bestimmten Beschaffenheit oder
                        Qualität und ohne die Vereinbarung der Eignung für einen bestimmten Zweck bereitgestellt.
                        Tresorvino ist insbesondere nicht verpflichtet, dafür Sorge zu tragen, dass die Plattform fehlerfrei
                        funktioniert und unterbrechungsfrei zu Verfügung steht. Eine bestimmte Verfügbarkeit der Plattform
                        ist nicht geschuldet. Tresorvino ist nicht zur Mängelbeseitigung verpflichtet, sofern ein Mangel nicht
                        arglistig verschwiegen wurde.</p>
                    <p>(4) Tresorvino hat das Recht, den laufenden Beta-Test auch bereits zu einem früheren Zeitpunkt ganz
                        oder teilweise für Sie ohne Ankündigung, Angabe von Gründen oder einer Frist zu beenden. Auf
                        Grund einer vorzeiten Beendingung des Beta-Test entstehen keinerlei Ansprüche gegen Tresorvino.</p>
                    <p>(5) Nach Beendigung des Beta-Test werden Sie beim nächsten Aufrufen der Plattform aufgefordert,
                        sich zu entscheiden, ob Sie in den Regelbetrieb der endgültigen Produktversion der Plattform
                        übergeführt werden wollen. Soweit Sie der Überführung den Regelbetrieb zustimmen, werden Sie
                        und Ihre erfassten Daten nach Installation der endgültigen Produktversion automatisch in den Betrieb
                        überführt.</p>
                    <p>(6) Tresorvino übernimmt keine Gewähr für Richtigkeit und Vollständigkeit der auf der Plattform
                        befindlichen Information. Verweise und Links auf Websites Dritter bedeuten nicht, dass sich
                        Tresorvino die hinter dem Verweis oder Link liegenden Inhalte zu eigen macht. Die Inhalte
                        begründen keine Verantwortung von Verivox für die dort bereit gehaltenen Daten und Informationen.
                        Tresorvino hat keinen Einfluss auf die hinter dem Link liegenden Inhalte. Für rechtswidrige,
                        fehlerhafte oder unvollständige Inhalte und für Schäden, die aufgrund der Nutzung von einem hinter
                        dem Link liegenden Inhalt verursacht worden sind, haftet Tresorvino daher nicht.</p>
                    <p>(7) Vorstehende Haftungsausschlüsse bzw. -beschränkungen gelten auch im Hinblick auf die Haftung
                        der Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen der baroxx
                        Projektmanagement GmbH (Betreiber von Tresorvino) oder deren Partner.</p>
                    <p>(8) Im Übrigen ist eine Haftung gleich aus welchem Rechtsgrund ausgeschlossen.</p>
                    <p>(9) Die auf der Plattform dargestellten Informationen setzen sich aus verschiedenen, zum Teil von
                        Ihnen oder von unseren Partnern bezogenen, Teilinformationen zusammen. Tresorvino haftet
                        insbesondere nicht dafür, dass die übermittelten Informationen richtig und vollständig sind. Wir
                        haften ebenfalls nicht dafür, sollte Ihnen durch fehlerhafte, unvollständige oder verspätete
                        Übermittlung von Informationen bzw. eine Störung der Anwendung ein Schaden entstehen. Wir sind
                        bemüht, durch die hinterlegten Server für Download-, Upload-, Ping- und Webseitentests eine
                        möglichst geringe Beeinträchtigung durch äußere Umstände zu erreichen und somit einen optimalen
                        Testablauf zu gewährleisten. Sollte die Anwendung oder die Übermittlung von Daten zu
                        Beeinträchtigungen oder Beschädigungen der Hard- oder Software führen, so haften wir für diese
                        nur, soweit sie durch Vorsatz oder grobe Fahrlässigkeit durch uns verursacht wurden.</p>    
                    <p>
                        <img src="../../assets/img/terms-and-condition-stamp.png" alt="stamp" srcset="">
                    </p>
                </ul>
            </div>
            <div class="col-12" *ngIf="currentLanguage == 'EN'">
                <h4>General Terms of Use of the www.Tresorvino.de platform</h4><br>

                <h5>§ 1 General, Scope of Application</h5><br>
                <p>(1a) These General Terms of Use apply to all legal relationships between Baroxx Projektmanagement GmbH
                    (hereinafter:
                    ‘Platform Operator’) and the users of the website www.tresorvino.de and all sub-pages (hereinafter
                    also: ‘Website’).
                    A user is anyone who registers on the website.
                </p>
                <p>(1b)<strong> During the beta test phase, §15 is to be taken into account</strong>, which replaces or suspends some
                    of the following paragraphs.</p>
                <p>(2) Any of the user's regulations that differ from these shall not apply unless the platform operator
                    has explicitly
                    confirmed this in writing. Individual agreements shall always take precedence.
                </p>
                <p>(3) The platform operator shall not store the wording of the contract after the contract has been
                    concluded and it is
                    therefore not accessible. The language of the contract is German. The user can access, store and
                    print out these
                    General Terms and Conditions of Use.
                </p>
                <p>(4) The business relationships between the platform operator and the user are subject to the laws of
                    the Federal
                    Republic of Germany. For consumers, this choice of law applies only to the extent that the
                    protection granted by
                    mandatory provisions of the law of the state in which the consumer has his habitual residence is not
                    withdrawn. The
                    application of the UN Sales Convention is excluded.
                </p>
                <p>(5) The place of jurisdiction is Heidelberg, provided that the user is a merchant, a legal entity
                    under public law or
                    a special fund under public law. The same applies if a customer does not have a general place of
                    jurisdiction in
                    Germany or if their domicile or usual place of residence is not known at the time of the
                    commencement of
                    proceedings.
                </p>
                <p>(6) Duty to inform in accordance with the Consumer Dispute Resolution Act (Section 36 VSBG): The
                    platform operator is
                    neither willing nor obliged to participate in further dispute resolution proceedings before a
                    consumer arbitration
                    board.
                </p>
                <p>(7) The platform operator is neither willing nor obliged to participate in a dispute resolution
                    procedure with
                    consumers before a consumer arbitration board. However, consumers have the option of using an
                    alternative dispute
                    resolution. The following link of the EU Commission (also called the OS platform) contains
                    information about online
                    dispute resolution and serves as a central point of contact for out-of-court settlement of disputes
                    arising from
                    online sales contracts or online service contracts: <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>.
                </p>
                <p>(8) The platform operator is prepared to achieve an out-of-court settlement with commercial users
                    through the
                    following mediators:
                </p>
                <p class="alignedParagraph">currently still under negotiation</p>
                <P>Before initiating a mediation procedure, an attempt should be made to settle the dispute directly
                    with the platform
                    operator.
                </P>

                <br>


                <h5>§ 2 Services of the platform operator, contents of the website</h5><br>
                <p>(1) Tresorvino is a platform on which users can create their own virtual wine cellar based on their
                    real stock. With regard to possible increases in value of certain individual wines, the platform
                    operator determines the value of the individual wines in the wine collection on a weekly basis using
                    a global market analysis. On the platform, wine lovers and interested parties can display their wine
                    collection, network, exchange information and share their own wine cellar with other users if they
                    wish. Furthermore, users can create their own posts via communities or participate in communities of
                    other users. Users also have the option of exchanging individual wines or even their entire wine
                    collection with each other (see § 7 of the terms of use).
                </p>
                <p>(2) The platform operator sells virtual Vino Coins. The user can purchase Vino Coins using various
                    payment services. If a user defaults on their payment obligations, the platform operator can demand
                    compensation and/or withdraw from the contract in accordance with the statutory provisions.
                <p>Vino-Coins can be used for special functions. These include, in addition to the ad-free display
                    and the use of advanced reporting, participation in the marketplace and the exchange of data between
                    exchange partners.</p>
                <p>In addition, credit in Vino-Coins does not represent a legally recognised asset outside of the
                    sales platform offering. Credits that have been paid in by the user are generally non-refundable
                    (i.e. they cannot be paid out or otherwise reimbursed). However, the exclusion of repayment does not
                    affect the user's statutory rights of revocation, withdrawal, termination, warranty and defect
                    rights, or any other mandatory rights, and does not restrict his or her payment and other claims in
                    this regard. Similarly, the right to repayment is not restricted provided that the deposited credit
                    has not become time-barred. The limitation period begins three years after the end of the calendar
                    year in which the last payment on the credit was made.</p>
                <p>The Vino-Coins are, unless otherwise stated, including the applicable sales tax (currently 19%).
                    The user receives an invoice from the platform operator for purchased Vino-Coins in the settings
                    area of the user account.</p>
                <p>(3) Through constant market value analysis, users have the opportunity to keep an eye on the market
                    value of their own wine stock as well as the individually activated wine stock of other users, and
                    to use their own wine cellar as an investment on this basis.
                </p>
                <p>(4) The market prices of the wines displayed by the platform operator are average values of the
                    corresponding market values offered on the internet, as well as the price indicators (bid/ask) that
                    can be maintained by users and the guide prices on the marketplace. Each price has an indication
                    using traffic light colours. By moving the mouse cursor over the displayed traffic lights, users can
                    obtain more detailed information about the corresponding colours (link ‘mouse over traffic light
                    explanation “prices”’).
                </p>
                <p>(5) The platform operator can refer users of the website to third-party content and applications
                    (hereinafter referred to as ‘third-party content’) by means of links. Such third-party content is
                    clearly marked as such. If and to the extent that the conclusion of a contract is offered in
                    connection with this third-party content, this is concluded exclusively with the respective platform
                    operator.
                </p>
                <p>(6) The platform operator can restrict access to its own services if the security of the network
                    operation, the maintenance of the network integrity, in particular the avoidance of serious
                    disruptions to the network, the software or stored data, so require.
                </p>

                <br>

                <h5>§ 3 Use as a registered member</h5><br>
                <p>(1) Active use of the website is only possible after prior registration as a member. A user can
                    register for free. The user accesses differ in the scope of the usability of the platform, e.g. with
                    regard to the information offered, the search options, the use of the community and an exchange
                    option (Link Community). The platform operator reserves the right to change the usage options for
                    private or business members with a notice period of 4 weeks and to discontinue them in whole or in
                    part.
                </p>
                <p>(2) Registration is carried out by opening a user account as a private or business member, thereby
                    agreeing to these General Terms of Use and acknowledging the data protection declaration (link to
                    data protection declaration). Upon completion of the registration process, a contract for the use of
                    the website (hereinafter referred to as ‘user contract’) is concluded between the platform operator
                    and the respective user. Until the registration access is completed, the user can correct his
                    entries directly in the corresponding input fields using the usual keyboard and mouse functions. The
                    platform operator confirms the conclusion of the user contract to the user by email. With this
                    email, the user also receives these General Terms and Conditions of Use, a cancellation policy and a
                    data protection declaration. There is no right to the conclusion of a user contract.
                </p>
                <p>(3) Commercial users are obliged to indicate the commercial use of the user account when registering.
                    The platform operator then marks the respective user account as ‘commercial user’. When using the
                    platform for the commercial distribution of goods, commercial users are obliged to comply with the
                    legal information requirements towards consumers. In particular, commercial users are obliged to
                    create their own legal notice and to provide information on the statutory consumer protection
                    regulations. Reference to the provisions and use of these terms of use is prohibited.
                </p>
                <p>(4) Only natural persons of full legal capacity are permitted to register. Minors are not allowed to
                    register with the platform operator. Each user may only have one account; transfer of access is not
                    possible.
                </p>
                <p>(5) The data requested by the platform operator during registration must be provided completely and
                    correctly, e.g. first and last name, current address (no P.O. box) and telephone number, valid
                    e-mail address. If the data provided changes subsequently, the user is obliged to correct the
                    information immediately. Commercial users are also obliged to provide their company name, registered
                    office and the corresponding address.
                </p>
                <p>(6) When registering, users shall provide a username and choose a password. The email address
                    provided shall be used for communication between the platform operator and the users. Users must
                    keep their password secret.
                </p>
                <p>(7) Each user is obliged to inform the platform operator immediately if there are indications that
                    their access has been misused. Each user is generally liable for all activities carried out using
                    their access and shall indemnify the platform operator from any third-party claims for damages,
                    unless the user is not responsible for the misuse.
                </p>
                <br>

                <h5>§ 4 Duration of fee-based access, discontinuation of the website by the platform operator</h5><br>
                <p>(1) The term of use of additionally purchased content or functions via Vino-Coins is one month. After
                    this period, the user must reactivate the content by spending Vino-Coins.
                </p>
                <p>(2) Registration as a private or business member is unlimited. A private or business member can
                    terminate their user contract at any time on the portal under the settings, in writing or by email
                    (kündigung@tresorvino.de). The user data will be deleted within one week of receipt of the
                    cancellation.
                </p>
                <p>(3) The platform operator reserves the right to discontinue the website altogether. In this case, the
                    users will be informed by email at least 6 weeks in advance. Any credit balances (Vino-Coins)
                    existing at this point in time will expire immediately upon discontinuation of the service.
                </p>
                <p>(4) The right to extraordinary termination remains unaffected.
                </p>

                <br>


                <h5>§ 5 Special provisions for the conclusion of contracts for members</h5><br>
                <p>(1) In the context of exchange opportunities for users with a private or business membership, the
                    platform operator is an online exchange platform. The wines offered are products from third parties,
                    the users of the platform. Offers posted by the platform operator are marked accordingly.
                </p>
                <p>(2) A wine exchange contract is always concluded between the respective users; the platform operator
                    only provides the technical platform for this and does not become a party to the exchange contracts.
                    The platform operator is only an exchange partner in the case of his own offers.
                </p>
                <p>(3) An exchange contract between users is concluded in accordance with the statutory provisions by
                    offer and acceptance. The creation and publication of one's own wine stock does not constitute an
                    offer to conclude an exchange contract. The realisation of a contractual relationship requires an
                    individual agreement between the respective users that has come about through communicative
                    exchange. The general terms and conditions of the respective users, if available, apply to the
                    exchange contracts; these users are also responsible for fulfilling all their obligations to provide
                    instruction. Individual agreements always take precedence. Otherwise, the statutory provisions on
                    exchange contracts apply.
                </p>
                <p>(4) In this regard, the platform operator reserves the right to analyse changes in the user portfolio
                    and to draw conclusions about possible exchange behaviour. If the platform operator detects a
                    violation of this provision, then § 6 and § 7 of these terms of use shall apply accordingly.
                </p>
                <p>(5) The actual execution of the exchange contract is the responsibility of the corresponding users.
                    Essential parts of the contract (such as names, addresses, number and destination of the wines,
                    shipping) are provided by the platform operator to the respective exchange partner in exchange for
                    the payment of a virtual Vino coin.
                    A price negotiated on the marketplace is only an indication for the following legal transaction to
                    be
                    concluded elsewhere, outside of the platform operator's platform. Insofar as the exchange in the
                    marketplace includes at least one business customer as a partner, the use of the price indication
                    must
                    also be understood to include the legally required sales tax. The platform operator provides a
                    highlighted display of business users and their price indications.
                    The current market values (guideline values) of the products determined by the platform operator do
                    not
                    constitute a basis of business within the meaning of § 2 (2) of the terms of use. The platform
                    operator
                    does not assume any liability for the correctness of these guideline values (see § 10 (2)).
                </p>
                <p>(6) Users may only use the information obtained from other users during the contract negotiations for
                    the purpose of contractual or pre-contractual communication. Any further processing of the
                    information is prohibited.
                </p>
                <p>(7) After the contract has been fully executed, the platform operator shall transfer the
                    corresponding virtual inventory to the user account of the corresponding user. The platform operator
                    reserves the right to transfer the virtual inventory to the original user account in the event of
                    serious breaches of contractual obligations. Serious breaches of duty shall be deemed to have
                    occurred, for example, if a user is not provided with the contractually agreed products and the
                    other contractual partner is unable to provide evidence of actual delivery.
                </p>
                <p>(8) The platform operator can restrict access to its own services if the security of the network
                    operation and/or the maintenance of the network integrity, in particular the avoidance of serious
                    disruptions to the network, the software or stored data, so require.
                </p>

                <br>
                <h5>§ 6 User conduct, exemption in the event of violations</h5><br>
                <p>(1) The Platform Operator does not check the accuracy and legality of user contributions on the
                    website. However, if the Platform Operator becomes aware that a user is violating these General
                    Terms and Conditions of Use or legal regulations with their contribution, the illegal content will
                    be removed immediately or access to it will be blocked.
                </p>
                <p>(2) The user alone is responsible for ensuring that they have all rights with regard to the content
                    they publish and that no third-party rights are violated as a result. If, for example, images
                    protected by trademark law are to be uploaded, this is only possible with the consent of the licence
                    holder. By publishing content, the user irrevocably grants the platform operator, free of charge,
                    the right to use and exploit the content provided by him on the website without any restrictions in
                    terms of territory, time or content. Any other use of the content transmitted by the user is only
                    permitted with the user's consent. Under no circumstances does the content represent the opinion of
                    the platform operator and the platform operator does not adopt it as its own.
                </p>
                <p>(3) Each user is obliged not to use the website to publish content or transmit messages that
                </p>
                <ul>
                    <li>are immoral, pornographic, racist or otherwise offensive,
                    </li>
                    <li>are factually incorrect or deliberately untrue,
                    </li>
                    <li>contain a quality seal, guarantee mark or other symbols of third parties,
                    </li>
                    <li>infringe the rights of third parties, in particular copyrights and protected rights under the
                        German Trademark Act (MarkenG),
                    </li>
                    <li>otherwise violate applicable laws or constitute a criminal offence,
                    </li>
                    <li>contain viruses or other computer programmes that can damage software or hardware or impair the
                        use of computers,
                    </li>
                    <li>are surveys or chain letters or disguised advertising, or
                    </li>
                    <li>serve the purpose of collecting and/or using personal data from other users, in particular for
                        business purposes.
                    </li>
                </ul>
                <p>(4) Should the platform operator become aware of a violation of the above provisions, it reserves the
                    right to modify or delete the post/advertisement. Should third parties assert claims for damages
                    against the platform operator due to such a violation, the responsible user shall indemnify the
                    platform operator against these.
                </p>

                <br>

                <h5>§ 7 Blocking</h5><br>
                <p>(1) The platform operator can take the following measures if there are concrete indications that a
                    user is violating legal regulations, the rights of third parties or these general terms and
                    conditions of use, or if the platform operator has any other legitimate interest, in particular to
                    protect other users:
                </p>
                <ul>
                    <li>Warning of users,</li>
                    <li>Provisional, partial or final blocking.</li>
                </ul>
                <p>(2) The platform operator can also permanently exclude a user from actively using the website
                    (permanent block) if the user provided false contact information when registering, in particular a
                    false or invalid email address, if the user causes significant harm to other users or the platform
                    operator, or for any other good cause.
                </p>
                <p>(3) As soon as a user has been blocked temporarily or permanently, they may no longer use the website
                    with other user accesses and may not re-register.
                </p>

                <br>

                <h5>§ 8 System Integrity and Disruption of the Website</h5><br>
                <p>(1) Users may not use any mechanisms, software or other scripts in connection with the use of the
                    website that could disrupt the functioning of the website, in particular those that enable the
                    generation of automated page views or page views.
                </p>
                <p>(2) Users may not take any measures that could result in an unreasonable or excessive load on the
                    infrastructure.
                </p>
                <p>(3) Users may not block, overwrite or modify any content generated by the platform operator or
                    interfere with the website in any other way.
                </p>

                <br>

                <h5>§ 9 Responsibility for content, liability</h5><br>
                <p>(1) The platform operator does not guarantee that the information and documentation provided on the
                    website is complete, correct and up to date. This also applies to all links contained on the
                    website. The platform operator is not responsible for the content of a page accessed via such a
                    link.
                </p>
                <p>(2) In particular, the platform operator shall not be liable for the market values of the products
                    determined by him within the meaning of § 2 (2) of the terms of use. The user has no right to
                    disclosure of the factors used to determine the prices or to disclosure of the names of the platform
                    operators whose prices serve as the basis for calculating the market value determined on the
                    platform.
                </p>
                <p>(3) Outside of liability for material defects and defects of title, the platform operator shall be
                    liable without limitation insofar as the cause of the damage is based on intent or gross negligence.
                    The platform operator is also liable for the slightly negligent breach of essential obligations
                    (obligations whose breach jeopardizes the achievement of the purpose of the contract) and for the
                    breach of cardinal obligations (obligations whose fulfilment is essential for the proper execution
                    of the contract and on whose compliance the user regularly relies), but only for foreseeable,
                    contract-typical damage. In particular, the platform operator is only liable for the loss of data up
                    to the amount of the expense that would arise if the user had regularly and adequately performed a
                    data backup, thereby ensuring that lost data could be restored at a reasonable cost. The platform
                    operator is not liable for the slightly negligent breach of obligations other than those mentioned
                    above.
                </p>
                <p>(4) The limitations of liability of the above paragraph do not apply to injury to life, limb and
                    health, to a defect after the assumption of a guarantee and to fraudulently concealed defects.
                </p>
                <p>(5) If the platform operator's liability is excluded or limited, this also applies to the personal
                    liability of employees, representatives and agents. </p>

                <br>

                <h5>§ 10 Data protection</h5><br>
                <p>(1) The user is aware and consents to the fact that the personal data required for the processing of
                    the user relationship is stored on data carriers. The user expressly consents to the collection,
                    processing and use of his personal data for the purpose of executing the user contract. This
                    includes all data required for the proper processing of the contract between the user and the
                    platform operator, in particular name, address, contact details (telephone and fax number, e-mail
                    address); but also the anonymous evaluation of files that have been requested, as well as the name
                    of the file, the date and time of the query, the amount of data transferred, the access status (file
                    transferred, file not found, etc.), a description of the type of web browser used.
                </p>
                <p>(2) Within the framework of the community function, personal data of users are generally not visible
                    to other users. In the event of a link with other users (adding to a friends list), personal data of
                    the linked users (first name, last name, company name) will be visible on the respective accounts if
                    they agree to this. Consent to the visibility of personal data is given by confirming the link to
                    other users (confirming the addition to a friends list).
                </p>
                <p>(3) The platform operator is only entitled to collect, store, modify or use the user's personal data
                    for advertising purposes with the user's separate consent. Under no circumstances is the platform
                    operator entitled to transmit this data to third parties in a list or otherwise summarised form for
                    advertising purposes.
                </p>
                <p>(4) The platform operator shall treat the stored personal data confidentially. The collection,
                    processing and use of personal data is carried out in compliance with the Federal Data Protection
                    Act (BDSG) and the Telemedia Act (TMG). The user has the right to revoke his consent at any time
                    with effect for the future. In this case, the platform operator is obliged to delete the user's
                    personal data. In the case of a user's current contractual relationship, the data will be deleted
                    after the contract has ended.
                </p>

                <br>

                <h5>§ 11 Protection of minors and age verification</h5>
                <p>By placing an order, the user confirms that he has reached the minimum age required to purchase the
                    age-restricted products.
                </p>
                <p>The products subject to age restriction include alcoholic beverages.</p>
                <br>

                <h5>§ 12 Amendments to these GTC</h5>
                <p>The platform operator reserves the right to amend these GTC at any time and without stating reasons.
                    The amended conditions will be sent to users by email no later than two weeks before they come into
                    effect. If a user does not object to the validity of the new GTC within six weeks of receiving the
                    email, the amended GTC shall be deemed accepted. The platform operator will specifically draw the
                    user's attention to the significance of this deadline in the email containing the amended
                    conditions.
                </p>

                <br>

                <h5>§ 14 Right of withdrawal</h5>
                <p>Consumers have the right to withdraw from this contract within fourteen days without giving reasons.
                    The cancellation period is fourteen days from the date of the contract.
                </p>
                <p>To exercise your right of cancellation, you must inform us of Tresorvino data by means of a clear
                    statement (eg a letter sent by post, fax or e-mail) of your decision to withdraw from this contract.
                </p>
                <p>To meet the cancellation deadline, it is sufficient for you to send your communication concerning
                    your exercise of the right of cancellation before the cancellation period has expired.
                </p>

                <br>

                <h5>Consequences of cancellation</h5>
                <p>If you cancel this contract, we will reimburse to you all payments received from you, including the
                    costs of delivery (except for the supplementary costs arising if you choose a type of delivery other
                    than the least expensive type of standard delivery offered by us), without undue delay and in any
                    event not later than 14 days from the day on which we are informed about your decision to withdraw
                    from this contract. We will carry out such repayment using the same means of payment that you used
                    for the original transaction, unless expressly agreed otherwise with you; under no circumstances
                    will you be charged any fees in connection with such repayment.
                </p>
                <p>If you have requested that the services should commence during the cancellation period, you shall pay
                    us a reasonable amount corresponding to the proportion of the services already provided by the time
                    you notify us of the exercise of the right of cancellation in respect of this contract compared to
                    the total services provided for in the contract.
                </p>

                <br>

                <h5>Specimen cancellation form</h5><br>

                <p>If you wish to cancel the contract, please complete this form and return it to: </p>
                <span>Tresorvino</span><br>
                <span>Baroxx Projektmanagement GmbH</span><br>
                <span>Am Büchsenackerhang 66</span><br>
                <span>69118 Heidelberg</span> <br><br><br>

                <ul style="list-style-type:none;">
                    <li>- I/We (*) hereby give notice that I/We (*) cancel my/our (*) contract for the provision of the
                        following service (*)... <br> (additional information, if applicable)</li>
                    <hr class="formLine">
                    <hr class="formLine">
                    <hr class="formLine">
                    <li>- Ordered on (*)/ received on (*):</li>
                    <hr class="formLine">
                    <li>- Name of the consumer(s):</li>
                    <hr class="formLine">
                    <li>- Address of the consumer(s):</li>
                    <hr class="formLine">
                    <br><br>
                    <hr class="formLine" style="margin-bottom: 0.5rem; margin-top: 3rem; width:60%; margin-left:0px;">

                    <p>Signature of consumer(s) (only for paper-based notification) </p>

                    <hr class="formLine" style="margin-bottom: 0.5rem; margin-top: 3rem; width:45%; margin-left:0px;">

                    <p>Date<br><br><br></p>
                    <h5>§ 15 Beta Version</h5>
                    <p>(1) The beta version is still in a test phase to be completed. You must assume that not all product
                        features are fully functional yet. The use of this beta version is therefore at your sole risk.</p>
                    <p>(2) Tresorvino reserves the right to change these terms of use at any time with effect for the future,
                        insofar as this</p>
                    <p class="sub-point">a) is required to adapt to developments that were not foreseeable at the start of the usage relationship
                        and that Tresorvinox has not caused or can influence and whose non-consideration would disturb the
                        balance of the usage relationship to a significant extent and provided that essential provisions of the
                        terms of use are not affected or;</p>
                    <p class="sub-point">b) is required to eliminate significant difficulties in the execution of the contract due to gaps in the
                        provisions arising after the start of the usage relationship or;</p>
                    <p class="sub-point">c) is required for a valid reason that could not be foreseen at the start of the usage relationship and this
                        change does not shift the relationship between performance and consideration to the disadvantage of
                        the partner, so that the change is reasonable for the partner.</p>
                    <p class="sub-point">d) Tresorvino shall notify the change to the terms and conditions of use in text form no later than two
                        weeks before it comes into effect. If you do not object to the changes to the terms and conditions of
                        use before they come into effect, the amended terms and conditions of use shall be deemed to have
                        been accepted. If you object to the amended terms and conditions of use within the time limit,
                        Tresorvino is entitled to restrict or prohibit the use of the platform.</p>
                    <p>(3) The beta version is therefore provided to you without agreement of a specific nature or quality and
                        without agreement of suitability for a specific purpose. In particular, Tresorvino is not obliged to
                        ensure that the platform functions without error and is available without interruption. A specific
                        availability of the platform is not guaranteed. Tresorvino is not obliged to rectify defects, provided that
                        a defect has not been fraudulently concealed.</p>
                    <p>(4) Tresorvino has the right to terminate the current beta test for you, either in whole or in part, at an
                        earlier point in time without notice, stating reasons or a deadline. No claims may be made against
                        Tresorvino due to premature termination of the beta test.</p>
                    <p>(5) After completion of the beta test, you will be requested the next time you access the platform to
                        decide whether you want to be transferred to the standard operation of the final product version of the
                        platform. If you agree to the transfer to standard operation, you and your recorded data will be
                        automatically transferred to operation after installation of the final product version.</p>
                    <p>(6) Tresorvino does not guarantee the accuracy and completeness of the information on the platform.
                        References and links to third-party websites do not mean that Tresorvino adopts the content behind the
                        reference or link as its own. The content does not establish any responsibility on the part of Verivox
                        for the data and information provided there. Tresorvino has no influence on the content behind the
                        link. Tresorvino shall therefore not be liable for illegal, incorrect or incomplete content or for damages
                        caused by the use of content accessed via the link.</p>
                    <p>(7) The above exclusions and/or limitations of liability shall also apply with regard to the liability of
                        the employees, staff, representatives and vicarious agents of baroxx Projektmanagement GmbH (the
                        operator of Tresorvino) or its partners.</p>
                    <p>(8) Otherwise, liability is excluded, regardless of the legal reason.</p>
                    <p>(9) The information displayed on the platform is comprised of various pieces of information, some of
                        which are obtained from you and some from our partners. In particular, Tresorvino is not liable for the
                        accuracy and completeness of the information transmitted. We are also not liable if you incur damages
                        due to the faulty, incomplete or delayed transmission of information or a malfunction of the
                        application. We endeavour to minimise the impact of external circumstances through the use of
                        dedicated servers for download, upload, ping and website tests, thus ensuring an optimal test
                        procedure. Should the application or the transmission of data result in impairment or damage to
                        hardware or software, we shall only be liable for this if it has been caused by us through intent or gross
                        negligence.</p>
                    <p>
                        <img src="../../assets/img/terms-and-condition-stamp.png" alt="stamp" srcset="">
                    </p>
                </ul>

            </div>
        </div>
    </div>
</section>