<footer>
    <div class="footer-one d-flex flex-column flex-md-row">
        <div class="left-side">
            <img src="../assets/img/TresorVino-Logo-white.png" class="footer-logo">
        </div>
        <div class="right-side d-flex flex-column">
            <div class="section-one">
                <span class="font-bold heading">{{ 'home.about_us' | translate }}</span>
                <p class="m-0 mt-3">{{ 'home.about_us_desc' | translate }}</p>
            </div>
            <div class="section-two d-flex justify-content-between justify-content-md-start">
                <div class="links d-flex flex-column mr-2 mr-md-5">
                    <span class="heading font-bold">{{ 'home.links' | translate }}</span>
                    <span><a routerLink="/privacy-policy">{{ 'home.privacy' | translate }}</a></span>
                    <span><a routerLink="/terms-and-conditions">{{ 'home.terms' | translate }}</a></span>
                    <span><a routerLink="/imprint">{{ 'home.disclaimer' | translate }}</a></span>
                </div>
                <!-- <div class="follow-us d-flex flex-column mr-2 mr-md-5">
                    <span class="heading font-bold mb-1">{{ 'home.follow_us' | translate }}</span>
                    <ul class="m-0 p-0 social-list">
                        <li>
                            <a href="#">
                                <img src="../../../../assets/icons/facebook-icon.svg">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="../../../../assets/icons/insta-icon.svg">
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src="../../../../assets/icons/x-icon.svg">
                            </a>
                        </li>
                    </ul>
                </div> -->
                <div class="contact-us d-flex flex-column">
                    <span class="heading font-bold">{{ 'home.contact_us' | translate }}</span>
                    <span>
                        Tresorvino<br>
                        Baroxx Projektmanagement GmbH<br>
                        HRB 722280 (Amtsgericht Mannheim)<br>
                        Am Büchsenackerhang 66
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-two">
        &copy; {{ 'home.copyright_text' | translate }}
    </div>
</footer>