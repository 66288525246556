<div class="verify-container">
    <div class="verify-page-shade"></div>
    <div class="forgot-password-wrapper">
        <div id="forgotPassword" class="p-4 my-3 normal-verify">
            <div class="blurred-background"></div>
            <form [formGroup]="verifyEmailForm" (ngSubmit)="onVESubmit()">
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <label class="allLabels">{{ 'login.verification_code' | translate }}</label>
                        <small id="emailHelp" class="form-text text-muted">{{ 'login.enter_code_msg' | translate }}
                            {{userName}}</small><br>
                        <input type="text" class="form-control allInputBoxes" formControlName="verifycode"
                            placeholder="{{'login.verification_code_msg' | translate}}"
                            [ngClass]="{ 'is-invalid': submitted && verificationDetails.verifycode.errors }" />

                        <div *ngIf="submitted && verificationDetails?.verifycode?.errors" class="invalid-feedback">
                            <div *ngIf="verificationDetails.verifycode.errors.required">{{ 'login.code_required' | translate }}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <br>
                    <button type="submit" class="btn btn-sm tr-btn-primary">{{ 'login.verify' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>