/* eslint-disable lines-between-class-members */
import { Component, OnInit } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/sharedServices/authentication.service';
import LoadingIndicatorService from 'src/app/sharedServices/loading-indicator.service';
import { ToastService } from 'src/app/sharedServices/toast.service';
import moment from 'moment';
import { CommunitiesService } from 'src/app/communities/services/communities.service';
import HttpService from 'src/app/sharedServices/http.service';
import UserDataService from 'src/app/sharedServices/user-data.service';
import awsconfig from 'src/aws-exports';
import { UserProfileDataService } from 'src/app/user-profile/user-profile-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export default class LoginComponent implements OnInit {
  userName: string;
  loginForm: FormGroup;
  submitted = false;
  showLogin = true;
  promoUser = false;
  hide = true;

  constructor(
    private route: Router,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private data: AuthenticationService,
    private loadingIndicator: LoadingIndicatorService,
    private toastService: ToastService,
    private communityService: CommunitiesService,
    private httpService: HttpService,
    private userDataService: UserDataService,
    private userProfileService: UserProfileDataService,
  ) { }

  ngOnInit(): void {
    this.data.currentUserName.subscribe((userName) => this.userName = userName);
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  passwordValidator(c: FormControl): any {
    const passwordRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_=+-]).{8,24}$',
    );
   if(c.value===""){
   return {
      validatePassword: {
        valid: true,
      },
    };
   }
    return passwordRegex.test(c.value)
      ? null
      : {
        validatePassword: {
          valid: false,
        },
      };
  }

  onLoginSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.loadingIndicator.show();
      this.signInToAWS(this.loginForm.value.email, this.loginForm.value.password);
    }
  }

  onReset() {
    this.submitted = false;
    this.loginForm.reset();
  }

  get logInDetails() {
    return this.loginForm.controls;
  }

  signInToAWS(email: HTMLInputElement, password: HTMLInputElement) {
    this.userName = this.loginForm.value.email.toLowerCase();
    this.data.changeUserDetails(this.userName);
    const authInfo = {
      username: this.loginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password,
    };

    Auth.signIn(authInfo).then((user) => {
      this.userDataService.getUserAccess$.next(true);
      localStorage.setItem('access_token', user.signInUserSession.accessToken.jwtToken);
      const isFirstTime = localStorage.getItem('firstLogin');
      if (isFirstTime === 'TRUE') {
        this.communityService.joinOfficialCommunity().subscribe(() => {
          localStorage.removeItem('firstLogin');
        });
        if (user.attributes.hasOwnProperty('address') && user.attributes.address !== 'Not updated') {
          const fullAddress = JSON.parse(user.attributes.address);
          const country = fullAddress.country;
          const pincode = fullAddress.postalCode;
          const city = fullAddress.city;
          const state = fullAddress.state;
          this.userProfileService.getInputPincodeMismatch(country, pincode, city, state);
          this.httpService.getPostalCodesForNewUser(fullAddress.postalCode, fullAddress.country);
        }
      }
      this.onReset();
      this.showLogin = false;
      this.loadingIndicator.hide();
      this.route.navigate(['/dashboard']);
    }).catch((err) => {
      this.loadingIndicator.hide();
      if (err.message == 'User does not exist.') {
        this.toastService.showError(this.translate.instant('login.registration_message'));
      } else if (err.message == 'User is not confirmed.') {
        this.route.navigate(['/verify-account']);
        this.onReset();
        Auth.resendSignUp(authInfo.username).then(() => {
          console.log('code resent successfully');
        }).catch((e) => {
          console.log(e);
        });
      } else {
        this.toastService.showError(this.translate.instant('login.valid_credentials_msg'));
      }
    });
  }

  fbSignIn() {
    Amplify.configure(awsconfig);
    setTimeout(async () => {
      const customState = '001=Facebook'
      await Auth.federatedSignIn({
        customProvider: 'Facebook',
        customState
      })
      .then(() => console.log('Federated sign-in successful'))
      .catch(err => console.error('Error with federated sign-in:', err));
    }, 2000)
  }
  
  googleSignIn(){
    Amplify.configure(awsconfig);
    setTimeout(async () => {
      const customState = '001=Google'
      await Auth.federatedSignIn({
        customProvider: 'Google',
        customState
      })
      .then(() => console.log('Federated sign-in successful'))
      .catch(err => console.error('Error with federated sign-in:', err));
    }, 2000)
  }
}
