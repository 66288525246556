import { JsonPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  setLocalStorage(key, value, encode?: boolean): void {
    if (encode) {
      localStorage.setItem(key, btoa(JSON.stringify(value)));
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  getLocalStorage(key, decode?: boolean): any {
    if (decode) {
      const data = localStorage.getItem(key);
      return data?.length ? JSON.parse(atob(data)) : null;
    } else {
      return JSON.parse(localStorage.getItem(key));
    }
  }

  removeLocalStorage(key): any {
    localStorage.removeItem(key);
  }

  setSessionStorage(key, value): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getSessionStorage(key): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  removeSessionStorage(key): any {
    sessionStorage.removeItem(key);
  }

  setCookie(cookieName: string, data: any): void {
    const date = new Date();
    date.setTime(date.getTime() + (30*24*60*60*1000)); // Cookie set for 30 days
    let expires = "expires="+ date.toUTCString();
    document.cookie = cookieName + "=" + JSON.stringify(data) + ";" + expires + ";path=/";
  }

  getCookie(cname: string): string {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  deleteCookie(cookieName): void {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  setReferralUserDetails(value): void {
    localStorage.setItem("referralUserDetails", JSON.stringify(value));
  }

  removeReferralUserDetails(): void {
    localStorage.removeItem("referralUserDetails");
  }

  getReferralUserDetails() {
    return JSON.parse(localStorage.getItem("referralUserDetails"));
  }
}
