/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { environment} from "../src/environments/environment";

const awsmobile = {
    "aws_project_region": environment.aws_project_region,
    "aws_cognito_identity_pool_id": environment.aws_cognito_identity_pool_id,
    "aws_cognito_region": environment.aws_cognito_region,
    "aws_user_pools_id": environment.aws_user_pools_id,
    "aws_user_pools_web_client_id": environment.aws_user_pools_web_client_id,

    "aws_appsync_graphqlEndpoint": environment.aws_appsync_graphqlEndpoint,
    "aws_appsync_graphqlWSEndpoint": environment.aws_appsync_graphqlWSEndpoint,
    "aws_appsync_graphqlHost": environment.aws_appsync_graphqlHost,
    "aws_appsync_region": environment.aws_appsync_region,
    "aws_appsync_authenticationType": environment.aws_appsync_authenticationType,
    "aws_appsync_apiKey": environment.aws_appsync_apiKey,
    "oauth": {
        "domain": environment.oauth.domain,
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": environment.oauth.redirectSignIn,
        "redirectSignOut": environment.oauth.redirectSignOut,
        "responseType": environment.oauth.responseType
    },
    "federationTarget": environment.federationTarget,
    "Storage": {
        "AWSS3": {
            "bucket": environment.Storage.AWSS3.bucket, //REQUIRED -  Amazon S3 bucket name
            "region": environment.Storage.AWSS3.region //OPTIONAL -  Amazon service region
        }
    },
};


export default awsmobile;
