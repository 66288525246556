import { Injectable } from '@angular/core';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';

@Injectable({
  providedIn: 'root'
})
export class UiTourApiService {
  private activeTourId = '';

  constructor(private tourService: TourService) { }

  showTour(tourData: IStepOption[]): void {
    this.tourService.initialize(tourData, {
      enableBackdrop: true,
    });
    this.tourService.start();
  }

  deregisterTour(): void {
    this.tourService.end();
  }

  setTourId(tourId: string): void {
    this.activeTourId = tourId;
  }

  getTourStatus(): void {
    // API call to get tour status
  }

  setTourStatus(): void {
    // API call to set tour status
  }

  handleTourEvents(): void {
    this.tourService.events$.subscribe(x => console.log(x));
  }
}
