import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UserIdleModule } from 'angular-user-idle';
import { HttpLink } from 'apollo-angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { setTheme } from 'ngx-bootstrap/utils'; // or 'bs4'
import { AmplifyService } from 'aws-amplify-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import AppRoutingModule from './app-routing.module';
import AppComponent from './app.component';
import HeaderComponent from './shared/components/header/header.component';
import { AuthenticationService } from './sharedServices/authentication.service';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { PaypalComponent } from './paypal/paypal.component';
import { VerifyAccountComponent } from './authentication/verify-account/verify-account.component';
import { FederatedUserDetailsComponent } from './federated-user-details/federated-user-details.component';
import { CanDeactivateGuardService } from './sharedServices/can-deactivate-guard.service';
import GraphQLModule from './graphql.module';
import NotificationsComponent from './notifications/notifications.component';
import SharedModule, { httpTranslateLoader } from './shared/shared.module';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SvgAnimationComponent } from './home/svg-animation/svg-animation.component';
import LoginComponent from './authentication/login/login.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { RegistrationComponent } from './authentication/registration/registration.component';
import { CookieConsentComponent } from './shared/components/cookie-consent/cookie-consent.component';
import { AdsenseModule } from 'ng2-adsense';
import { HomeNewComponent } from './home-new/home-new.component';
import { LottieModule } from 'ngx-lottie';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RouterModule } from '@angular/router';

registerLocaleData(localeDe, 'DE');

setTheme('bs4');

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    PaypalComponent,
    VerifyAccountComponent,
    FederatedUserDetailsComponent,
    NotificationsComponent,
    TermsConditionsComponent,
    ImprintComponent,
    PrivacyPolicyComponent,
    SvgAnimationComponent,
    StripePaymentComponent,
    RegistrationComponent,
    CookieConsentComponent,
    HomeNewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    UserIdleModule.forRoot({ idle: 900, timeout: 900, ping: 2 }),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    SharedModule,
    GraphQLModule,
    AdsenseModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    TourMatMenuModule.forRoot()
  ],
  providers: [
    AmplifyService,
    AuthenticationService,
    HttpLink,
    CanDeactivateGuardService,
  ],
  bootstrap: [AppComponent],
})
// eslint-disable-next-line import/prefer-default-export
export class AppModule { }