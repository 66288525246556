<!-- <ng-adsense [adClient]="'ca-pub-1115035479249914'"></ng-adsense> Currently no need of this tag-->
<div class="tr-main-wrapper" [ngClass]="{ 'public-theme': !authStatus }">
  <app-prelogin-header *ngIf="!authStatus"></app-prelogin-header>
  <header *ngIf="viewLoaded && authStatus">
    <app-header></app-header>
  </header>
  <main [ngClass]="{ 'public-page-container': !authStatus || !viewLoaded }" *ngIf="viewLoaded">
    <div class="page-container" [ngClass]="{'mb-0': noMarginBottom}">
      <router-outlet></router-outlet>
    </div>
  </main>
  <app-footer></app-footer>
  <app-share *ngIf="authStatus"></app-share>
  <app-toast></app-toast>
  <app-cookie-consent></app-cookie-consent>
  <ng-container>
    <div *ngFor="let levelUp of levelUpData; let i = index">
      <app-level-up *ngIf="showLevelUpPopup[i]" [userlevelData]="levelUp" [allLevelsData]="levelsData" (onClosePopup)="closeLevelUpPopup(i)"></app-level-up>
    </div>
  </ng-container>
  <ng-container>
    <div *ngFor="let earnVc of earnVcData; let i = index">
      <app-vino-coins *ngIf="showVinoPopup[i]" (onClosePopup)="closeVinoCoinPopup(i)" [earnVcCount]="earnVc.earnVcCount" [earnVcDescription]="earnVc.earnVcDescription"></app-vino-coins>
    </div>
  </ng-container>
  <app-session-warning-popup [countdown]="countDownTimeSec" (onContinueSession)="keepLoggedIn()" (onLogoutUser)="onLogOut()" *ngIf="showSessionModal"></app-session-warning-popup>
  <div class="updated-terms-acceptance" *ngIf="updatedTCAcceptance">
    <div class="backdrop"></div>
    <div class="modal fade show tr-modal" tabindex="-1" role="dialog" aria-labelledby="sell wine"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content tr-shadow">
          <div class="modal-header">
            <h5 class="modal-title">{{ 'login.terms_conditions' | translate }}</h5>
          </div>
          <div class="modal-body">
            <div class="modelText">
                <app-terms-conditions></app-terms-conditions>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn tr-btn-primary" (click)="onTermsAccept()">{{ 'common.accept_terms' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <tour-step-template></tour-step-template>
</div>
