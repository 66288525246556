import { Injectable } from '@angular/core';
// import { getNotifications as getNotificationsQuery }  from '../graphql/subscriptions.graphql';
import { Notification } from './notification.model';
import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import awsConfig from '../../aws-exports';
import { NormalizedCacheObject } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { getUnreadNotificationsQuery } from '../graphql/queries.graphql';
import { markNotificationAsRead } from '../graphql/mutations.graphql';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  client: AWSAppSyncClient<NormalizedCacheObject>;
  private ws: WebSocket;
  userId: string = '';
  pushLiveNotification$ = new Subject();

  constructor(private apollo: Apollo) {
    // const payload = {};
    // const headerPayload = btoa(JSON.stringify({
    //   "Authorization": localStorage.getItem('access_token'),
    //   "host": 'u2muje3c7bg2jo4umhve3k3n2i.appsync-api.eu-central-1.amazonaws.com',
    // }));

    // this.client = new AWSAppSyncClient({
    //   url: `wss://wssdev.api.tresorvino.de/graphql?header=${headerPayload}&payload=${btoa(JSON.stringify({}))}`,//'wss://wssdev.api.tresorvino.de/graphql',
    //   // url: 'https://u2muje3c7bg2jo4umhve3k3n2i.appsync-api.eu-central-1.amazonaws.com/graphql',//'wss://wssdev.api.tresorvino.de/graphql',
    //   region: awsconfig.aws_appsync_region,
    //   auth: {
    //     type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    //     // jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
    //     // apiKey: '4cbqk37j6nb43pjjvqim6zhsz4'
    //     jwtToken: localStorage.getItem('access_token'),
    //   },
    // });
  }

  getUnreadNotifications() {
    const unreadNotifications = this.apollo.query<any>({
      query: getUnreadNotificationsQuery,
    });
    return unreadNotifications;
  }

  connect(userId: string): void {
    this.userId = userId;
    const headerPayload = btoa(JSON.stringify({
      "Authorization": localStorage.getItem('access_token'),
      "host": awsConfig.aws_appsync_graphqlHost
    }));
    const url = `${awsConfig.aws_appsync_graphqlWSEndpoint}?header=${headerPayload}&payload=${btoa(JSON.stringify({}))}`;
    this.ws = new WebSocket(url, ['graphql-ws', 'graphql-transport-ws']);
    this.ws.onopen = () => {
      this.ws.send(JSON.stringify({
        type: "connection_init"
      }));

      this.ws.onmessage = (event) => {
        const response = JSON.parse(event.data);
        if (response.type === "connection_ack") {
          const getNotificationsQuery = `
          subscription MySubscription($user_id: String!) {
            onCreateNotification (user_id: $user_id) {
              category
              created_timestamp
              event_id
              payload {
                cellar_bk_id
                cellar_bk_nm
                user_wine_id
                wine_name
                community_id
                community_name
                post_id
                comment_id
                sales_id
                bid_detail_id
                sell_price
                new_level
                vino_coins
                description
                __typename
              }
              user {
                firstname
                user_id
                lastname
                __typename
              }
              invoker_user_id
              invoker_lastname
              invoker_firstname
              invoker_companyName
              invoker_userType
              invoker_vatNumber
              __typename
            }
          }
          `;

          const message = {
            payload: { 
              data: JSON.stringify({
              query: getNotificationsQuery,
              variables: {
                user_id: this.userId
              }}),
              extensions: {
                authorization: {
                  Authorization: localStorage.getItem('access_token'),
                  host: awsConfig.aws_appsync_graphqlHost
                },
              }
            }
          };

          const payload = JSON.stringify({
            id: crypto.randomUUID(),
            ...message,
            type: "start"
          });
    
          this.ws.send(payload);
        }

        if (response.type === "data") {
          this.pushLiveNotification$.next(response.payload);
        }
      }
    };
    
    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.ws.onclose = () => {
      console.log('WebSocket connection closed');
    };
  }

  // getNotifications(userId: string){
  //   return this.client.subscribe({
  //     query: getNotificationsQuery,
  //     variables: {
  //       user_id: userId
  //     }
  //   });
  // }

  setNotificationAsRead(id): any {
    return this.apollo.mutate({
      mutation: markNotificationAsRead,
      variables: {
        eventId: id,
      }
    });
  }
}
