<div class="federated-container">
    <div class="federated-page-shade"></div>
    <div class="container">
        <div class="federated-wrapper" [ngClass]="{'with-heading': firstTimeLogin}">
            <div class="normal-federated mb-2">
                <div class="blurred-background"></div>
                <h5 class="federated-note text-white" *ngIf="firstTimeLogin">{{ 'common.federated_welcome_note' | translate }}</h5>

                <form [formGroup]="federatedUserDetailsForm" class="federatedUserDetailsForm" (ngSubmit)="onSubmit()">
                    <div class="form-group" *ngIf="isUserByReferral">
                      <mat-form-field class="white-field" appearance="fill">
                        <mat-label for="referralUserName">{{ 'login.referral_label' | translate }}</mat-label>
                        <input matInput type="text" maxlength="50" formControlName="referralUserName" readonly>
                      </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="white-field" appearance="fill">
                            <mat-label>{{ 'User type' | translate }}</mat-label>
                            <mat-select formControlName="userType" panelClass="white-field" (selectionChange)="onUserTypeSelect()">
                                <mat-option *ngFor="let type of userTypeList" [value]="type">{{ type }}</mat-option>
                            </mat-select>
                            <mat-error class="error-msg">{{ 'login.register_select_user_type' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="isUserTypeBusiness">
                        <mat-form-field appearance="fill" class="white-field">
                            <mat-label for="companyName">{{ 'login.company_name' | translate }}</mat-label>
                            <input matInput type="text" formControlName="companyName">
                            <mat-error class="error-msg">
                                <span *ngIf="federatedUserDetailsForm.get('companyName')?.errors?.required">{{ 'login.company_name_required' | translate }}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="isUserTypeBusiness">
                        <mat-form-field appearance="fill" class="white-field">
                            <mat-label for="vatNumber">{{ 'login.vat_number' | translate }}</mat-label>
                            <input matInput type="text" formControlName="vatNumber">
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-checkbox class="terms-conditions" formControlName="termsConditions" id="termsConditions"></mat-checkbox>
                        <span class="terms-conditions-text" (click)="tncOverlay = true">Terms and Conditions / data security</span>
                        <div class="error-msg" *ngIf="submitted && !federatedUserDetailsForm.get('termsConditions')?.value">{{ 'login.tc_Warning' | translate }}</div>
                    </div>
                    <div class="text-center btnOptions">
                      <button type="submit" class="btn tr-btn-primary login-button mr-3">{{ 'friends.confirm' | translate }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="" *ngIf="tncOverlay">
    <div class="modal fade show sell-wine tr-modal" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content tr-shadow">
                <div class="modal-header">
                    <h5 class="modal-title">{{ 'login.terms_conditions' | translate }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="tncOverlay = false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="modelText">
                        <app-terms-conditions></app-terms-conditions>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>