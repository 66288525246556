import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie';
import HttpService from '../sharedServices/http.service';
import UserDataService from '../sharedServices/user-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.css']
})
export class HomeNewComponent implements OnInit, AfterViewInit {
  isVideoPlaying = false;
  itemsPerSlide = 1;
  showCarousel = false;
  wineSlides = [];
  wineArticles = [
    {
      title: 'Blog/Article Title and second line space',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt testing text here.',
      imagePath: '../../assets/homepage/images/home-banner.png',
    },
    {
      title: 'Blog/Article Title and second line space',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt testing text here.',
      imagePath: '../../assets/homepage/images/home-banner.png',
    },
    {
      title: 'Blog/Article Title and second line space',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt testing text here.',
      imagePath: '../../assets/homepage/images/home-banner.png',
    },
  ]
  openFaqIndex = 0;
  currentSection = '';
  mainFaqList = [];
  langCode = '';

  dashboardDeskOptions: AnimationOptions = {
    path: '/assets/homepage/dashboard/dashboard-desktop.json',
    renderer: 'svg'
  };
  dashboardMobileOptions: AnimationOptions = {
    path: '/assets/homepage/dashboard/dashboard-mobile.json',
    renderer: 'svg'
  };
  vinoDeskOptions: AnimationOptions = {
    path: '/assets/homepage/vino-coins/vino-coins-desktop.json',
    renderer: 'svg'
  };
  vinoMobileOptions: AnimationOptions = {
    path: '/assets/homepage/vino-coins/vino-coins-mobile.json',
    renderer: 'svg'
  };
  wineDeskOptions: AnimationOptions = {
    path: '/assets/homepage/wine-database/wine-database-desktop.json',
    renderer: 'svg'
  };
  wineMobileOptions: AnimationOptions = {
    path: '/assets/homepage/wine-database/wine-database-mobile.json',
    renderer: 'svg'
  };
  p2pDeskOptions: AnimationOptions = {
    path: '/assets/homepage/p2p/p2p-desktop.json',
    renderer: 'svg'
  };
  p2pMobileOptions: AnimationOptions = {
    path: '/assets/homepage/p2p/p2p-mobile.json',
    renderer: 'svg'
  };
  commDeskOptions: AnimationOptions = {
    path: '/assets/homepage/community/global-community-desktop.json',
    renderer: 'svg'
  };
  commMobileOptions: AnimationOptions = {
    path: '/assets/homepage/community/global-community-mobile.json',
    renderer: 'svg'
  };
  assetDeskOptions: AnimationOptions = {
    path: '/assets/homepage/asset-class/asset-class-desktop.json',
    renderer: 'svg'
  };
  assetMobileOptions: AnimationOptions = {
    path: '/assets/homepage/asset-class/asset-class-mobile.json',
    renderer: 'svg'
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768 && this.itemsPerSlide === 3) {
      this.showCarousel = false;
      this.itemsPerSlide = 1;
      setTimeout(() => {
        this.showCarousel = true;
      }, 50);
    } else if (event.target.innerWidth >= 768 && this.itemsPerSlide === 1) {
      this.showCarousel = false;
      this.itemsPerSlide = 3;
      setTimeout(() => {
        this.showCarousel = true;
      }, 50);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const sections = document.querySelectorAll('.desktop-lottie-section .feature-video');
    sections.forEach(section => {
      const rect = section.getBoundingClientRect();
      const sectionId = section.id;
      if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
      // if (rect.top >= 0 && rect.top <= 250) {
        this.currentSection = sectionId;
      }
    });
  }

  constructor(
    private titleService: Title,
    private metaService: Meta,
    public translate: TranslateService,
    private httpService: HttpService,
    private userDataService: UserDataService,
    private router: Router,
  ) {
    this.titleService.setTitle('TresorVino - The Premier Platform for Fine Wine Trading and Investment');
    this.metaService.updateTag({ name: 'description', content: 'Join TresorVino, the ultimate destination for wine collectors and enthusiasts. Trade rare and fine wines, manage your collection with ease, and connect with a global community of connoisseurs. Explore curated selections, track wine investments, and earn rewards with Vino Coins.' });
  }

  ngOnInit(): void {
    if (!this.router.url.includes('#')) {
      window.scrollTo(0, 0);
    }
    if (window.innerWidth >= 768) {
      this.itemsPerSlide = 3;
    }
    this.setFaqData();
    this.translate.onLangChange.subscribe((data) => {
      this.setFaqData();
      this.langCode = data.lang;
    });
    this.langCode = this.userDataService.getLanguage();
    if (!this.langCode) {
      this.langCode = 'EN';
    }
    this.getFinestWines();
  }

  getFinestWines(): void  {
    this.httpService.getFinestWineOffers().subscribe((data: any) => {
      const youngestHighestPrice = data[0].YoungestHighestPrice.map((obj) => {
        return { ...obj, type: 'youngestHighestPrice' }
      });
      const highestAppreciation = data[0].YoungestHighestPrice.map((obj) => {
        return { ...obj, type: 'highestAppreciation' }
      });
      const lowestSpread = data[0].YoungestHighestPrice.map((obj) => {
        return { ...obj, type: 'lowestSpread' }
      });
      const oldestLowestPrice = data[0].YoungestHighestPrice.map((obj) => {
        return { ...obj, type: 'oldestLowestPrice' }
      });
      this.wineSlides = [...youngestHighestPrice, ...highestAppreciation, ...lowestSpread, ...oldestLowestPrice];
      this.wineSlides.forEach((wine) => {
        let wineImage = '../../assets/img/red-wine-bottle.jpg';
        if (wine.wine_type.toLowerCase().includes('red')) {
          wineImage = '../../assets/img/red-wine-bottle.jpg';
        } else if (wine.wine_type.toLowerCase().includes('rose')) {
          wineImage = '../../assets/img/rose-wine-bottle.jpg';
        } else if (wine.wine_type.toLowerCase().includes('white')) {
          wineImage = '../../assets/img/white-wine-bottle.jpg';
        } else if (wine.wine_type.toLowerCase().includes('mixed')) {
          wineImage = '../../assets/img/red-wine-bottle.jpg';
        }
        wine.wineImage = wineImage;
      });
      this.showCarousel = true;
    });
  }

  setFaqData(): void {
    this.mainFaqList = [
      {
        question: this.translate.instant('faqs.question_1'),
        answer: this.translate.instant('faqs.answer_1')
      },
      {
        question: this.translate.instant('faqs.question_2'),
        answer: this.translate.instant('faqs.answer_2')
      },
      {
        question: this.translate.instant('faqs.question_3'),
        answer: this.translate.instant('faqs.answer_3')
      },
      {
        question: this.translate.instant('faqs.question_4'),
        answer: this.translate.instant('faqs.answer_4')
      }
    ];
  }

  ngAfterViewInit() {
    setTimeout(() => {      
      // Create an IntersectionObserver instance
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const video: HTMLVideoElement = document.getElementById("home-video") as any;
  
            if (entry.isIntersecting && video.paused) {
              // Play video when section comes into view
              video.play();
              this.isVideoPlaying = true;
            } else {
              video.pause();
              this.isVideoPlaying = false;
            }
          });
        },
        {
          threshold: 0.5 // Play when at least 50% of the section is in view
        }
      );
      
      // Observe the section with the video
      const videoSection = document.querySelector('.video-container');
      if (videoSection) {
        observer.observe(videoSection);
      }
    }, 2000);
  }

  togglePlayPause(): void {
    const video = document.getElementById("home-video") as any;
    // const playPauseButton = document.querySelector(".play-pause-button");
    if (video.paused) {
      video.play();
      this.isVideoPlaying = true;
      // playPauseButton.textContent = "Pause";
    } else {
      video.pause();
      // playPauseButton.textContent = "Play";
    }
  }

  openAccordion(index: number): void {
    if (this.openFaqIndex === index) {
      this.openFaqIndex = -1;
    } else {
      this.openFaqIndex = index;
    }
  }
}
