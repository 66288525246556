import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import HttpService from '../sharedServices/http.service';
import { Apollo } from 'apollo-angular';
import { getPincodeMachingStatus, inputPincodeMatch } from '../graphql/queries.graphql';

@Injectable({
  providedIn: 'root'
})
export class UserProfileDataService {
  vinoPayment$ = new Subject();
  currentVinoCoins$ = new Subject();
  functionalityCharges$ = new Subject();

  constructor(
    private httpService: HttpService,
    private apollo: Apollo,
  ) { }

  storeVinoCoins(vino: any) {
    localStorage.setItem("currentUserVinoCoins", vino);
    this.currentVinoCoins$.next(vino);
  }

  getVinoCoins(): any {
    return localStorage.getItem("currentUserVinoCoins");
  }

  storeFunctionalityCharges(functionalityCharges: any) {
    let chargesJson = {};
    functionalityCharges.forEach(element => {
      if (element.func_cd == "VatPercentage" || element.func_cd == "VinoCoinValue") {
        chargesJson[element.func_cd] = element.currency_value
      } else {
        chargesJson[element.func_cd] = element.vino_coin
      }
    });
    localStorage.setItem("functionalityCharges", JSON.stringify(chargesJson));
    this.functionalityCharges$.next(functionalityCharges);
  }

  getFunctionalityCharges() {
    return JSON.parse(localStorage.getItem("functionalityCharges"));
  }

  setVinoCoins() {
    this.httpService.getVinoBalance().valueChanges.subscribe((response: any) => {
      const data = response.data.getCurrentUserVinoCoins;
      const currentVinoCoins = data.vinoCoins;
      const functionalityCharges = data.functionalityCharges;
      this.storeVinoCoins(currentVinoCoins);
      this.storeFunctionalityCharges(functionalityCharges)
    })
  }

  getInputPincodeMismatch(country, pincode, city, state) {
    this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: inputPincodeMatch,
      variables: {
        country: country,
        pincode: pincode,
        city: city,
        state: state,
      }
    }).valueChanges.subscribe(() => {});
  }

  getPincodeMachingStatus(country, pincode, city, state) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: getPincodeMachingStatus,
      variables: {
        country: country,
        pincode: pincode,
        city: city,
        state: state,
      }
    });
  }
}
