import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/sharedServices/authentication.service';
import { ToastService } from 'src/app/sharedServices/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']

})
export class ResetPasswordComponent implements OnInit {

  submitted = false;
  resetPasswordForm: FormGroup;
  resetPasswordFlag = false;
  userName: string;
  hideNewPassword = true;
  
  constructor(
    private route: Router, 
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private data: AuthenticationService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.data.currentUserName.subscribe(userName => this.userName = userName);

    this.resetPasswordForm = this.formBuilder.group({

      code: ['', [Validators.required, Validators.minLength(6)]],
      new_password: ['', [Validators.required, Validators.minLength(8),Validators.maxLength(24),this.passwordValidator]],
    });

  }

  passwordValidator(c: FormControl): any {
    const passwordRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_=+-]).{8,24}$',
    );
   if(c.value===""){
   return {
      validatePassword: {
        valid: true,
      },
    };
   }
    return passwordRegex.test(c.value)
      ? null
      : {
        validatePassword: {
          valid: false,
        },
      };
  }

  onResetPassword(code: HTMLInputElement, new_password: HTMLInputElement) {
    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(this.userName, this.resetPasswordForm.value.code, this.resetPasswordForm.value.new_password)
      .then(data => {
        this.resetPasswordFlag = false;
        this.toastService.showSuccess(this.translate.instant("login.password_change_success"));
        setInterval(() => {
          this.route.navigate(["/login"]);
          this.onReset();
        }, 1000);

      })
      .catch(err => {
        if (err.message == "Username/client id combination not found.") {
          this.toastService.showError(this.translate.instant("login.valid_email_warning"));
        }
        else if (err == "AuthError: Password cannot be empty") {
          alert(this.translate.instant("login.password_empty_warning"));
          this.resetPasswordForm.get('new_password')?.setErrors({ passwordEmptyWarning: true });
        }
        else if (err.message == "Invalid verification code provided, please try again.") {
          alert(this.translate.instant("login.invalid_code"));
          this.resetPasswordForm.get('code')?.setErrors({ invalidCode: true });
        }
        else if (err.message == "Attempt limit exceeded, please try after some time.") {
          this.toastService.showWarning(this.translate.instant("login.attempt_exceeded_warning"));
        }
        else {
          this.toastService.showError(this.translate.instant("login.valid_details"));
        }
      });

  }

  onRPSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.onResetPassword(this.resetPasswordForm.value.code, this.resetPasswordForm.value.new_password);
  }
  onReset() {
    this.submitted = false;
    this.resetPasswordForm.reset();

  }
  get resetDetails() {
    return this.resetPasswordForm.controls;
  }

}
