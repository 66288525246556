import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Component({
  selector: 'app-redirection-handler',
  templateUrl: './redirection-handler.component.html',
  styleUrls: ['./redirection-handler.component.css']
})
export class RedirectionHandlerComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    const tcAccept = this.route.snapshot.queryParamMap.get('tcUpdateAcceptance');
    if (Boolean(tcAccept)) {
      this.storageService.setCookie('tAndCAcceptance', true);
    }
    this.router.navigate(['./dashboard'], { relativeTo: this.route });
  }

}
